import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import Axios from "axios";
import {API} from "../../api/APIClient";
import ReactSelect from "../common/ReactSelect";
import ChatVariablesSelector from "components/chatflows/ChatVariablesSelector"
import Modal from "react-responsive-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UUID from "../../utils/UUID";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector";
import Utils from "../../utils/Utils";
import HabitsSelector from "../habits/HabitsSelector";
import {ChatActionType} from "../../constants/ChatActionType";
import PodcastsSelector from "../podcasts/PodcastsSelector";
import AssessmentsSelector from "../common/AssessmentsSelector";

export default class ChatConditionForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                condition: null,
                variable: null,
                comparator: null,
                value: null,
                params: {},
                typeText: "Condition",
                nodeText: null,
                typeID: ChatItemType.CONDITION,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            conditionOptions: props.node ? props.node.condition ? [props.node.condition] : [] : [],
            comparatorOptions: props.node ? props.node.comparator ? [props.node.comparator] : [] : [],
            categoryOptions: [],
            errors: {},
            touched: {},
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.validate();
        this.fetchConditions();
        this.getCategories();
    }

    getCategories = () => {
        Axios
            .get(API.chat.getWellbeingCategories)
            .then((res) => {
                if (res.data.success) {

                    let categoryOptions = res.data.data.categories.map((item) => { return {value: item.id.toString(), label: item.name} });

                    this.setState({
                        categoryOptions: categoryOptions
                    })
                }
            })
    }

    fetchConditions = () => {
        Axios
            .get(API.chat.getConditionsAndComparators)
            .then((res) => {
                if (res.data.success) {

                    let conditions = res.data.data.conditions;
                    let comparators = res.data.data.comparators;

                    this.setState({
                        conditionOptions: conditions,
                        comparatorOptions: comparators
                    }, () => {
                        this.validate()
                    })
                }
            });
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);

        if (name === "condition") {
            item.variable = null;
            item.comparator = null;
            item.value = null;
            if (value) {
                let condition = this.state.conditionOptions.find(element => element.value === value);
                if (condition) {
                    item[name] = condition;
                }
                else {
                    return;
                }
            }
        }
        else if (name === "comparator") {

            if (value) {
                let comparator = this.state.comparatorOptions.find(element => element.value === value);
                if (comparator) {
                    item[name] = comparator;
                }
                else {
                    return;
                }
            }
        }
        else if (name === "value") {
            item[name] = value;
        }
        else if (name.startsWith("param_")) {
            const paramName = name.replace("param_", "");
            item.params = Object.assign({}, item.params);
            item.params[paramName] = {
                value: value
            }
        }
        else {
            item[name] = value;
        }

        this.setState({
            item: item
        }, () => {
            this.validate();
            this.updateNodeText();
        });
    }

    updateNodeText = () => {

        let nodeText = "";

        if (this.state.item.condition) {
            let condition = this.state.conditionOptions.find(element => element.value === this.state.item.condition.value);
            if (condition) {
                nodeText = condition.label;
            }
        }

        if (this.state.item.comparator) {
            let comparator = this.state.comparatorOptions.find(element => element.value === this.state.item.comparator.value);
            if (comparator) {

                nodeText += " " + this.state.item.variable.key;

                if (this.state.item.params) {
                    let params = [];
                    Object.keys(this.state.item.params).forEach((key) => {
                        let value = this.state.item.params[key];
                        params.push(key+":"+value.value)
                    });
                    nodeText += " [" + params.join(",") + "]";
                }

                nodeText += " " + comparator.label;
            }
        }

        if (this.state.item.value) {
            const valueParsed = this.state.item.variable.type === "option" ? this.state.item.variable.options.find(element => element.value === this.state.item.value).label : this.state.item.value;
            nodeText += " " + valueParsed;
        }

        let item = Object.assign({}, this.state.item);
        item.nodeText = nodeText;

        this.setState({
            item: item
        })
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            console.log(this.state.item);
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.condition) {
            validated = false;
        }
        else {
            if (item.condition.showComparator) {
                if (!item.comparator) {
                    validated = false;
                }

                if (item.variable && item.comparator) {
                    if (item.variable.type === "option" && !item.value) {
                        validated = false;
                    }
                    else if (item.comparator.needsValue && !item.value) {
                        validated = false;
                    }
                }

                if (item.variable && item.variable.params) {
                    item.variable.params.forEach((param) => {
                        if (! item.params[param.key]) {
                            validated = false;
                        }
                    })
                }
            }
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                showCondVariables={true}
                showTextVariables={false}
                onSelected={(variable) => {
                    console.log(variable);
                    let item = Object.assign({}, this.state.item);
                    item.variable = variable;
                    item.isCustomVariable = false;
                    item.comparator = null;
                    item.value = null;
                    item.params = {};
                    this.setState({
                        item: item
                    }, () => {
                        this.closeModal();
                        this.validate();
                        this.updateNodeText();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
            this.updateNodeText();
        })
    }

    showCustomVariablesSelector = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                onSelected={(variable) => {
                    let item = Object.assign({}, this.state.item);
                    item.variable = variable;
                    item.isCustomVariable = true;
                    item.comparator = null;
                    item.value = null;
                    this.setState({
                        item: item
                    }, () => {
                        this.closeModal();
                        this.validate();
                        this.updateNodeText();
                    })
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
            this.updateNodeText();
        })
    }

    showPodcastModal = (item, key) => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <PodcastsSelector
                isSingleSelection
                onSubmitted={(podcast) => {

                    let item = Object.assign({}, this.state.item);
                    item.params[key] = {
                        title: podcast.title,
                        value: podcast.id
                    };

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                        this.updateNodeText();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
            this.updateNodeText();
        })

    }

    showCustomVariablesSelectorForValue = (item, key) => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                onSelected={(variable) => {

                    let item = Object.assign({}, this.state.item);
                    item.params[key] = {
                        title: variable.key,
                        value: variable.id
                    };

                    this.setState({
                        item: item
                    }, () => {
                        this.closeModal();
                        this.validate();
                        this.updateNodeText();
                    })
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
            this.updateNodeText();
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }


    showAssessmentModal = (item, key) => {
        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (item) {
            selected.push({id: item.value})
        }

        const content = (
            <AssessmentsSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(assessment) => {

                    let item = Object.assign({}, this.state.item);
                    item.params[key] = {
                        title: assessment.name,
                        value: assessment.id
                    };

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null
                        }
                    }, () => {
                        this.validate();
                        this.updateNodeText();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
            this.updateNodeText();
        })
    }


    showHabitModal = (item, key) => {
        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (item) {
            selected.push({id: item.value})
        }

        const content = (
            <HabitsSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(habit) => {

                    let item = Object.assign({}, this.state.item);
                    item.params[key] = {
                        title: habit.name,
                        value: habit.id
                    };

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null
                        }
                    }, () => {
                        this.validate();
                        this.updateNodeText();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
            this.updateNodeText();
        })
    }

    render() {

        let filteredComparators = [];

        if (this.state.item.variable) {
            this.state.comparatorOptions.forEach((item) => {
                if (item.dataTypes.includes(this.state.item.variable.type)) {
                    filteredComparators.push(item);
                }
            });

        }

        return (


            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat condition</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="condition" className="text-right middle">* Condition</label>
                            </div>
                            <div className="small-9 cell ">
                                <ReactSelect
                                    name="condition"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.condition}
                                    options={this.state.conditionOptions}
                                />
                            </div>
                        </div>
                        {this.state.item.condition && this.state.item.condition.showComparator &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="condition" className="text-right middle">* Property</label>
                                </div>
                                <div className="small-9 cell ">
                                    <div>
                                        <div className="input-group">
                                            {this.state.item.variable &&
                                            <span className="input-group-label"
                                                  style={{fontSize: 10}}>{this.state.item.isCustomVariable ? "Custom property" : "System property"}</span>
                                            }
                                            <input className="input-group-field" type="text" value={this.state.item.variable ? this.state.item.variable.key : "Not selected" }  disabled/>
                                            <div className="input-group-button">
                                                <button type="button" className="button" onClick={this.showVariablesSelector}>System Property</button>
                                                <button type="button" style={{marginLeft: 2}} className="button" onClick={this.showCustomVariablesSelector}>Custom Property</button>
                                            </div>
                                        </div>
                                        <small className="field-info">{this.state.item.variable ? this.state.item.variable.label : ""}</small>
                                    </div>
                                </div>
                            </div>
                            {this.state.item.variable &&
                            <React.Fragment>
                                {this.state.item.variable.params && this.state.item.variable.params.map((item, index) => {
                                    return (
                                        <div className="grid-x grid-padding-x" key={index}>
                                            <div className="small-3 cell">
                                                <label htmlFor={"param_" + index} className="text-right middle">* {item.title}</label>
                                            </div>
                                            <div className="small-9 cell ">
                                                {item.type === "habit" &&
                                                <div className="input-group">
                                                    <input className="input-group-field" name={"param_" + item.key} type="text" value={Utils.getNested(this.state.item.params, item.key, "title") || "Not selected" } disabled/>
                                                    <div className="input-group-button">
                                                        <button type="button" className="button" onClick={() => this.showHabitModal(Utils.getNested(this.state.item.params, item.key, "value"), item.key)}>Browse</button>
                                                    </div>
                                                    <small className="field-info">{item.desc}</small>
                                                </div>
                                                }
                                                {item.type === "assessment" &&
                                                <div className="input-group">
                                                    <input className="input-group-field" name={"param_" + item.key} type="text" value={Utils.getNested(this.state.item.params, item.key, "title") || "Not selected" } disabled/>
                                                    <div className="input-group-button">
                                                        <button type="button" className="button" onClick={() => this.showAssessmentModal(Utils.getNested(this.state.item.params, item.key, "value"), item.key)}>Browse</button>
                                                    </div>
                                                    <small className="field-info">{item.desc}</small>
                                                </div>
                                                }
                                                {item.type === "wellbeingCategory" &&
                                                <div className="small-9 cell">
                                                    <ReactSelect
                                                        name={"param_" + item.key}
                                                        className="react-select"
                                                        onChange={this.handleChange}
                                                        value={Utils.getNested(this.state.item.params, item.key, "value")}
                                                        options={this.state.categoryOptions}
                                                        onBlur={this.handleBlur}
                                                    />
                                                    <small className="field-info">{item.desc}</small>
                                                </div>
                                                }
                                                {item.type === "integer" &&
                                                    <React.Fragment>
                                                        <input name={"param_" + item.key} type="number" value={Utils.getNested(this.state.item.params, item.key, "value") || item.defaultValue || ""} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                        <small className="field-info">{item.desc}</small>
                                                    </React.Fragment>
                                                }
                                                {item.type === "customProperty" &&
                                                <div className="input-group">
                                                    <input className="input-group-field" name={"param_" + item.key} type="text" value={Utils.getNested(this.state.item.params, item.key, "title") || "Not selected" } disabled/>
                                                    <div className="input-group-button">
                                                        <button type="button" className="button" onClick={() => this.showCustomVariablesSelectorForValue(Utils.getNested(this.state.item.params, item.key, "value"), item.key)}>Browse</button>
                                                    </div>
                                                    <small className="field-info">{item.desc}</small>
                                                </div>
                                                }
                                                {item.type === "podcast" &&
                                                <div className="input-group">
                                                    <input className="input-group-field" name={"param_" + item.key} type="text" value={Utils.getNested(this.state.item.params, item.key, "title") || "Not selected" } disabled/>
                                                    <div className="input-group-button">
                                                        <button type="button" className="button" onClick={() => this.showPodcastModal(Utils.getNested(this.state.item.params, item.key, "value"), item.key)}>Browse</button>
                                                    </div>
                                                    <small className="field-info">{item.desc}</small>
                                                </div>
                                                }
                                                {item.type === "option" &&
                                                <div className="small-9 cell">
                                                    <ReactSelect
                                                        name={"param_" + item.key}
                                                        className="react-select"
                                                        onChange={this.handleChange}
                                                        value={Utils.getNested(this.state.item.params, item.key, "value") || item.defaultValue}
                                                        options={item.options}
                                                        onBlur={this.handleBlur}
                                                    />
                                                    <small className="field-info">{item.desc}</small>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="comparator" className="text-right middle">* Comparator</label>
                                    </div>
                                    <div className="small-9 cell ">
                                        <ReactSelect
                                            name="comparator"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={this.state.item.comparator}
                                            options={filteredComparators}
                                        />
                                    </div>
                                </div>
                                {this.state.item.comparator  &&
                                <React.Fragment>
                                    {this.state.item.variable.type === "option" &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="value" className="text-right middle">* Value</label>
                                        </div>
                                        <div className="small-9 cell ">
                                            <ReactSelect
                                                name="value"
                                                className="react-select"
                                                onChange={this.handleChange}
                                                value={this.state.item.value}
                                                options={this.state.item.variable.options}
                                            />
                                        </div>
                                    </div>
                                    }
                                    {(this.state.item.variable.type !== "option" && this.state.item.comparator.needsValue === true) &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="value" className="text-right middle">* Value</label>
                                        </div>
                                        <div className="small-9 cell ">
                                            <input name="value" type={this.state.item.variable.type === "integer" ? "number" : "text"} value={this.state.item.value} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    }
                                </React.Fragment>
                                }
                            </React.Fragment>
                            }
                        </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
