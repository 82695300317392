import React from 'react'
import Device from 'components/contentEditor/Device'
import LabelItem from 'components/contentEditor/Label'
import ReactSelect from "components/common/ReactSelect"
import { SketchPicker } from 'react-color'
import { DragDropContext, Droppable, Draggable, useDrag } from "react-beautiful-dnd"
import LabelEditor from 'components/contentEditor/LabelEditor'
import ButtonEditor from 'components/contentEditor/ButtonEditor'
import Utils from 'utils/Utils'
import ButtonItem from 'components/contentEditor/Button'
import ImageItem from 'components/contentEditor/Image'
import ImageEditor from "./ImageEditor"
import UUID from 'utils/UUID'
import {Button} from "react-foundation"
import BlockUi from "react-block-ui"
import AppComponent from 'components/common/AppComponent'
import TableItem from "./TableItem";
import TextField from "components/contentEditor/TextField"
import TextFieldEditor from "components/contentEditor/TextFieldEditor"
import TextView from "components/contentEditor/TextView"
import TextViewEditor from "components/contentEditor/TextViewEditor"
import Header from "components/contentEditor/Header"
import HeaderEditor from "components/contentEditor/HeaderEditor"
import Checkbox from "components/contentEditor/Checkbox"
import CheckboxEditor from "components/contentEditor/CheckboxEditor"
import SubmitButton from "components/contentEditor/SubmitButton"
import SubmitButtonEditor from "components/contentEditor/SubmitButtonEditor"
import {BASE_URL} from "../../api/APIClient";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


export default class ContentEditor extends AppComponent {

    constructor(props) {
        super(props);

        const structure = props.rawStructure || this.getStructure(props);

        this.state = {
            deviceScale: 1.0,
            fontTypeOptions: [
                {value: "primary", label: "Header"},
                {value: "secondary", label: "Body"}
            ],
            showColorPicker: false,
            defaults: structure.defaults,
            elements: structure.elements,
            selectedItem: null,
            isFetching: false
        }

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.state);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.content !== nextProps.content) {
            const structure = this.getStructure(nextProps);
            this.setState({
                defaults: structure.defaults,
                elements: structure.elements
            }, () => {
                this.props.onChange(this.state);
            })
        }
    }

    getStructure = (props) => {

        var elements = [];
        var defaults = {
            margins: {
                left: 20,
                right: 20,
                top: 0,
                bottom: 10
            },
            font: {
                type: "secondary",
                size: 18,
                color: "#565656"
            }
        };

        if (props.content && props.content !== null) {
            let content = Object.assign({}, props.content);
            defaults = content.style;
            elements = content.elements;

            elements.forEach(el => {
                if (!el.id) {
                    el.id = UUID.uuidv4();
                }

                if (el.type === "image") {
                    el.data.url = BASE_URL + "/" + el.data.url;
                }
            });

        }

        return {
            elements: elements,
            defaults: defaults
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        if (name === "margin-left" || name === "margin-right" || name === "margin-top" || name === "margin-bottom") {
            let type = name.replace("margin-", "");
            let defaults = Object.assign({}, this.state.defaults);
            defaults.margins[type] = parseInt(value);
            this.setState({
                defaults: defaults
            }, () => {
                this.props.onChange(this.state);
            })
        }
        else if (name === "font-type" || name === "font-size") {
            let attribute = name.replace("font-", "");
            let defaults = Object.assign({}, this.state.defaults);

            if (attribute === "size") {
                value = parseInt(value);
            }

            defaults.font[attribute] = value;
            this.setState({
                defaults: defaults
            }, () => {
                this.props.onChange(this.state);
            })
        }
    }

    handleColorChange = (color) => {
        let defaults = Object.assign({}, this.state.defaults);
        defaults.font.color = color.hex;
        this.setState({
            defaults: defaults
        }, () => {
            this.props.onChange(this.state);
        })
    }

    handleItemChange = (item) => {
        const index = this.state.elements.findIndex(i => i.id === item.id);

        if (index !== -1) {
            let elements = Array.from(this.state.elements);
            elements[index] = item;

            if (item.type === "checkbox") {
                for (let i = 0; i < elements.length; i++) {
                    let innerItem = elements[i];
                    if (innerItem.type === "checkbox" && innerItem.id !== item.id && innerItem.data.groupID === item.data.groupID) {
                        innerItem.data.type = item.data.type;
                    }
                }
            }

            let newState = {};
            newState.elements = elements;

            if (this.state.selectedItem && this.state.selectedItem.id === item.id) {
                newState.selectedItem = item;
            }

            this.setState(newState, () => {
                this.props.onChange(this.state);
            })
        }
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const elements = reorder(
            this.state.elements,
            result.source.index,
            result.destination.index
        );

        this.setState({
            elements: elements
        }, () => {
            this.props.onChange(this.state);
        })
    }

    handleItemClick = (item) => {
        this.setState({
            selectedItem: item
        }, () => {
            this.props.onChange(this.state);
        })
    }

    handleDelete = (item) => {
        const index = this.state.elements.findIndex(i => i.id === item.id);

        if (index !== -1) {
            let elements = Array.from(this.state.elements);
            elements.splice(index, 1);

            let newState = {
                elements: elements
            };

            if (this.state.selectedItem.id === item.id) {
                newState.selectedItem = null;
            }

            this.setState(newState, () => {
                this.props.onChange(this.state);
            });
        }
    }

    addElement = (type) => {
        let elements = Array.from(this.state.elements);

        if (type === "label") {
            elements.push({
                id: UUID.uuidv4(),
                type: "label",
                data: {
                    text: "Label"
                }
            })
        }
        else if (type === "button") {
            elements.push({
                id: UUID.uuidv4(),
                type: "button",
                data: {
                    title: "Button"
                }
            })
        }
        else if (type === "image") {
            elements.push({
                id: UUID.uuidv4(),
                type: "image",
                data: {
                    contentMode: "cover",
                    aspectRatio: 0.5,
                    url: ""
                }
            })
        }
        else if (type === "tableItem") {
            elements.push({
                id: UUID.uuidv4(),
                type: "tableItem",
                data: {
                    title: "List Item",
                    subtitle: "Subtitle"
                },
                style: {
                    margins: {
                        left: 20,
                        right: 20,
                        bottom: 0,
                        top: 0
                    }
                }
            })
        }
        else if (type === "textField") {
            elements.push({
                id: UUID.uuidv4(),
                type: "textField",
                data: {
                    key: UUID.uuidv4(),
                    leftText: "",
                    placeholder: ""
                }
            })
        }
        else if (type === "textView") {
            elements.push({
                id: UUID.uuidv4(),
                type: "textView",
                data: {
                    key: UUID.uuidv4()
                }
            })
        }
        else if (type === "questionHeader") {
            elements.push({
                id: UUID.uuidv4(),
                type: "questionHeader",
                data: {
                    key: UUID.uuidv4(),
                    leftText: "",
                    mainText: ""
                }
            })
        }
        else if (type === "checkbox") {
            elements.push({
                id: UUID.uuidv4(),
                type: "checkbox",
                data: {
                    key: UUID.uuidv4(),
                    text: "",
                    groupID: UUID.uuidv4(),
                    type: "single"
                }
            })
        }
        else if (type === "submit") {
            elements.push({
                id: UUID.uuidv4(),
                type: "button",
                data: {
                    title: "Submit",
                    action: {
                        type: "submit"
                    }
                }
            })
        }

        this.setState({
            elements: elements
        }, () => {
            this.props.onChange(this.state);
        })
    }

    getAvailableGroups = () => {

        var groups = [];
        var foundGroups = [];
        this.state.elements.forEach((item) => {
            if (item.type === "checkbox") {
                if (item.data.groupID) {
                    if (!foundGroups.includes(item.data.groupID)) {
                        groups.push({value:{groupID: item.data.groupID, type: item.data.type},label: item.data.groupID});
                        foundGroups.push(item.data.groupID);
                    }
                }
            }
        })

        return groups;
    }


    render() {

        return (
            <React.Fragment>
                <BlockUi tag="div" blocking={this.state.isFetching} message="Please wait" keepInView>
                    <div className="small-12 cell" >
                        <fieldset className="fieldset edit-field">
                            <legend>Global Settings</legend>
                            <div className="grid-x grid-padding-x">
                                <div className="cell small-12 medium-4 large-3 small-setts-box">
                                    <fieldset className="fieldset">
                                        <legend>Font</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-12 cell">
                                                <span>Type</span>
                                                <ReactSelect
                                                    classNamePrefix="editor"
                                                    name="font-type"
                                                    className="react-select"
                                                    onChange={this.handleChange}
                                                    value={this.state.defaults.font.type}
                                                    options={this.state.fontTypeOptions}
                                                />
                                            </div>
                                            <div className="medium-6 small-12 cell">
                                                <span>Size</span>
                                                <input name="font-size" type="number" min={0} value={this.state.defaults.font.size} onChange={this.handleChange} />
                                            </div>
                                            <div className="medium-6 small-12 cell">
                                                <span>Colour</span>
                                                <div className="input-group">
                                                    <input className="input-group-field" type="text" value={this.state.defaults.font.color} disabled/>
                                                    <div className="input-group-button color-picker">
                                                        <div>
                                                            <div className="swatch" onClick={ () => this.setState({showColorPicker: !this.state.showColorPicker}) }>
                                                                <div className="color" style={{backgroundColor: this.state.defaults.font.color}}/>
                                                            </div>
                                                            { this.state.showColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={ () => this.setState({showColorPicker: false})} />
                                                                    <SketchPicker color={ this.state.defaults.font.color } onChange={ this.handleColorChange } />
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="cell small-12 medium-4 large-3 small-setts-box">
                                    <fieldset className="fieldset">
                                        <legend>Margins</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-6 small-12 cell">
                                                <span>Left</span>
                                                <input name="margin-left" type="number" min={0} value={this.state.defaults.margins.left} onChange={this.handleChange} />
                                            </div>
                                            <div className="medium-6 small-12 cell">
                                                <span>Top</span>
                                                <input name="margin-top" type="number" min={0} value={this.state.defaults.margins.top} onChange={this.handleChange} />
                                            </div>
                                            <div className="medium-6 small-12 cell">
                                                <span>Right</span>
                                                <input name="margin-right" type="number" min={0} value={this.state.defaults.margins.right} onChange={this.handleChange} />
                                            </div>
                                            <div className="medium-6 small-12 cell">
                                                <span>Bottom</span>
                                                <input name="margin-bottom" type="number" min={0} value={this.state.defaults.margins.bottom} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="grid-x grid-padding-x" style={{marginTop: 20, marginBottom: 100}}>
                        <div className="cell small-12 medium-shrink">
                            <Device onScaleUpdated={(scale) => this.setState({deviceScale: scale}) } >
                                {this.state.elements.length > 0 &&
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    paddingTop: this.state.defaults.margins.top,
                                                    paddingBottom: this.state.defaults.margins.bottom
                                                }}
                                            >
                                                {this.state.elements.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided, snapshot) => {

                                                            const isSelected = Utils.getNested(this.state.selectedItem, "id") === item.id;

                                                            if (item.type === "label") {
                                                                return (
                                                                    <LabelItem
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            } else if (item.type === "button") {
                                                                return (
                                                                    <ButtonItem
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            } else if (item.type === "image") {
                                                                return (
                                                                    <ImageItem
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            } else if (item.type === "tableItem") {
                                                                return (
                                                                    <TableItem
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            } else if (item.type === "textField") {
                                                                return (
                                                                    <TextField
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            } else if (item.type === "textView") {
                                                                return (
                                                                    <TextView
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            }
                                                            else if (item.type === "questionHeader") {
                                                                return (
                                                                    <Header
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            }
                                                            else if (item.type === "checkbox") {
                                                                return (
                                                                    <Checkbox
                                                                        scale={this.state.deviceScale}
                                                                        onClick={() => this.handleItemClick(item)}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            background: snapshot.isDragging ? "lightgreen" : (isSelected ? "#91ee9152" : "white"),
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        item={item}
                                                                        defaults={this.state.defaults}
                                                                    />
                                                                )
                                                            }
                                                        }}
                                                    </Draggable>
                                                ))}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                }
                                {this.state.elements.length === 0 &&
                                <div className="placeholder">
                                    <div className="tuto">
                                        <span></span>
                                        <span>Nothing has been added yet</span>
                                        <span>Add an element from the 'Add new element' box to begin</span>
                                    </div>
                                </div>
                                }
                            </Device>
                        </div>
                        <div className="cell small-12 medium-auto">
                            <div className="grid-container xlarge-grid-container">
                                <div className="grid-x grid-padding-x">

                                    <div className="cell small-12 add-ele">
                                        <fieldset className="fieldset edit-field">
                                            <legend>Add new element</legend>
                                            <div className="button-group">
                                                <Button onClick={() => this.addElement("label")}>Label</Button>
                                                {this.props.labelsOnly !== true &&
                                                    <React.Fragment>
                                                        <Button onClick={() => this.addElement("button")}>Button</Button>
                                                        <Button onClick={() => this.addElement("image")}>Image</Button>
                                                        <Button onClick={() => this.addElement("tableItem")}>List Item</Button>
                                                    </React.Fragment>
                                                }
                                            </div>
                                            {this.props.showFormElements &&
                                                <React.Fragment>
                                                    <p style={{marginBottom: 0, marginTop: 20}}><small>Form elements</small></p>
                                                    <div className="button-group">
                                                        <Button onClick={() => this.addElement("questionHeader")}>Header</Button>
                                                        <Button onClick={() => this.addElement("textField")}>Text Field</Button>
                                                        <Button onClick={() => this.addElement("textView")}>Text View</Button>
                                                        <Button onClick={() => this.addElement("checkbox")}>Checkbox</Button>
                                                        <Button onClick={() => this.addElement("submit")}>Submit Button</Button>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            <div className="help-icon" style={{display: "none"}}>?</div>
                                        </fieldset>
                                    </div>

                                    <div className="cell small-12 item-editor">
                                        {this.state.selectedItem && this.state.selectedItem.type === "label" &&
                                        <LabelEditor
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                            fontTypeOptions={this.state.fontTypeOptions}
                                        />
                                        }
                                        {this.state.selectedItem && ((this.state.selectedItem.type === "button" && Utils.getNested(this.state.selectedItem, "data", "action", "type") !== "submit") || this.state.selectedItem.type === "tableItem") &&
                                        <ButtonEditor
                                            organisationID={this.props.organisationID}
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                        />
                                        }
                                        {this.state.selectedItem && (this.state.selectedItem.type === "button" && Utils.getNested(this.state.selectedItem, "data", "action", "type") === "submit") &&
                                        <SubmitButtonEditor
                                            organisationID={this.props.organisationID}
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                        />
                                        }
                                        {this.state.selectedItem && this.state.selectedItem.type === "image" &&
                                        <ImageEditor
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                        />
                                        }
                                        {this.state.selectedItem && this.state.selectedItem.type === "textField" &&
                                        <TextFieldEditor
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                        />
                                        }
                                        {this.state.selectedItem && this.state.selectedItem.type === "textView" &&
                                        <TextViewEditor
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                        />
                                        }
                                        {this.state.selectedItem && this.state.selectedItem.type === "questionHeader" &&
                                        <HeaderEditor
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                        />
                                        }
                                        {this.state.selectedItem && this.state.selectedItem.type === "checkbox" &&
                                        <CheckboxEditor
                                            key={this.state.selectedItem.id}
                                            item={this.state.selectedItem}
                                            onChange={this.handleItemChange}
                                            onDelete={this.handleDelete}
                                            groups={this.getAvailableGroups()}
                                        />
                                        }
                                        {!this.state.selectedItem &&
                                        <div className="placeholder">
                                            <div className="tuto">
                                                <span>How to build the page</span>
                                                <span>1. Select a new element to add from the 'Add new element' box</span>
                                                <span>2. Select an element you have added in the phone layout to edit it</span>
                                                <span>3. Drag and drop page elements within the phone layout to change their order</span>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        )
    }

}