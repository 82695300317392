import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import UUID from "../../utils/UUID";
import ChatVariablesSelector from "./ChatVariablesSelector";
import Modal from "react-responsive-modal";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Utils from "../../utils/Utils";
import ReactSelect from "../common/ReactSelect";

export default class ChatTextFieldForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                texts: [{id: UUID.uuidv4(), text: props.node.text || ""}], //legacy
                validation: {
                    type: null,
                    error: null,
                    data: null
                },
                nodeText: null,
                responseProperty: null,
                responseValue: null,
                saveResponse: 0,
                typeText: "Message with text field prompt",
                typeID: ChatItemType.TEXTFIELD,
                useCustomTypingTime: null,
                typingTime: 0,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            validationTypes: [
                {"value": null, "label": "None"},
                {"value": "valid_time", "label" : "Valid time"},
                {"value": "in_list", "label": "Response in list"}
            ],
            errors: {},
            touched: {},
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);

        if (name.startsWith("text_")) {
            const index = parseInt(name.replace("text_", ""));
            item.texts[index].text = value;
            item.nodeText = item.texts[0].text;
        }
        else if (name.startsWith("validation_")) {
            const paramName = name.replace("validation_", "");
            item.validation = Object.assign({}, item.validation);
            item.validation[paramName] = value;
        }

        if (name === "saveResponse") {
            item.responseProperty = null;
        }

        if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        }

        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }

    // a little function to help us with reordering the result
    reorderItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    addAlternateText = () => {
        let item = Object.assign({}, this.state.item);
        item.texts.push({id: UUID.uuidv4(), text: ""});
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    removeTextVariation = (index) => {
        let item = Object.assign({}, this.state.item);
        item.texts.splice(index, 1);
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const texts = this.reorderItems(
            this.state.item.texts,
            result.source.index,
            result.destination.index
        );

        let item = Object.assign({}, this.state.item);
        item.texts = texts;

        this.setState({
            item: item
        }, () => {
            this.validate();
        })
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.texts || item.texts.length === 0) {
            validated = false;
        }

        for (let i=0; i < item.texts.length; i++) {
            let textValue = item.texts[i].text;
            if (!textValue || textValue.length === 0) {
                let name = "text_".i;
                if (touched[name]) {
                    errors[name] = "Text is required";
                }
                validated = false;
            }
        }

        if (item.saveResponse) {
            if (!item.responseProperty) {
                if (touched.responseProperty) {
                    errors.text = "Property is required is save response selected"
                }
                validated = false;
            }
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showCustomVariablesSelector = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                onSelected={(variable) => {
                    let item = Object.assign({}, this.state.item);
                    item.responseProperty = variable;
                    this.setState({
                        item: item
                    }, () => {
                        this.closeModal();
                        this.validate();
                    })
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelectorForText = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }


    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat message with text field prompt</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {this.state.item.texts.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            userSelect: "none",
                                                            background: snapshot.isDragging ? "lightgreen" : "white",
                                                            ...provided.draggableProps.style
                                                        }}
                                                    >
                                                        <div className="grid-x grid-padding-x" key={index}>
                                                            <div className="small-3 cell">

                                                                <label htmlFor={"text_" + index} className="text-right middle">
                                                                    {index !== 0 &&
                                                                    <button style={{height:5}} className="button clear alert" type="button" onClick={() => this.removeTextVariation(index)}>
                                                                        <FontAwesomeIcon icon="times-circle"/>
                                                                    </button>
                                                                    }
                                                                    {index == 0 ? "* Text" : "* Alternate text"}
                                                                </label>
                                                            </div>
                                                            <div className={"large-9 small-9 cell " + (this.state.errors["text_" + index] && "error")}>
                                                                <textarea rows={5} name={"text_" + index} value={item.text} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                                <small className="error">{this.state.errors["text_" + index]}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">

                            </div>
                            <div className="large-9 small-9 cell ">
                                <button className="button primary" type="button" onClick={this.addAlternateText}>Add alternate text</button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="validation_type" className="text-right middle">Validation</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.validation_type && "error")}>
                                <ReactSelect
                                    name={"validation_type"}
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.validation.type}
                                    options={this.state.validationTypes}
                                    onBlur={this.handleBlur}
                                />
                            </div>
                        </div>
                        {this.state.item.validation.type === "in_list" &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="validation_data" className="text-right middle">* Allowed responses</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.validation_data && "error")}>
                                <input name="validation_data" type="text" value={this.state.item.validation.data} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <span className="error">{this.state.errors.validation_data}</span>
                                <small className="field-info">Please enter valid responses separated by comma. Ideally each response is a single word however multiple words are allowed too. Validation is case insensitive.</small>
                            </div>
                        </div>
                        }
                        {this.state.item.validation.type &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="validation_error" className="text-right middle">* Validation error</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.validation_error && "error")}>
                                <input name="validation_error" type="text" value={this.state.item.validation.error} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <span className="error">{this.state.errors.validation_error}</span>
                                <small className="field-info">Validation error will be shown to user if the validation fails.</small>
                            </div>
                        </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">System properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button" onClick={this.showVariablesSelector}>Show properties</button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">Custom properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button" onClick={this.showCustomVariablesSelectorForText}>Show properties</button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="data.showHeader" className="text-right middle">Save response</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="saveResponse" onChange={this.handleChange}
                                           checked={this.state.item.saveResponse}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.saveResponse === 1 &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="condition" className="text-right middle">* Save response as</label>
                                </div>
                                <div className="small-9 cell ">
                                    <div>
                                        <div className="input-group">
                                            <input className="input-group-field" type="text" value={this.state.item.responseProperty ? this.state.item.responseProperty.key : "Not selected" }  disabled/>
                                            <div className="input-group-button">
                                                <button type="button" style={{marginLeft: 2}} className="button" onClick={this.showCustomVariablesSelector}>Select Custom Property</button>
                                            </div>
                                        </div>
                                        <small className="error">{this.state.errors.responseProperty}</small>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime" onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                    <input name="typingTime" type="number" value={this.state.item.typingTime} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.typingTime}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
