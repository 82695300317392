import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import UUID from "../../utils/UUID";
import isSvg from 'is-svg'
import ReactSelect from "../common/ReactSelect";
import {DayTime} from "../../constants/DayTime";

export default class ChatAnimationForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                code: null,
                title: null,
                nodeText: null,
                altText: null,
                size: "small",
                typeText: "Animation",
                useCustomDisplayTime: null,
                displayTime: 0,
                useCustomTypingTime: null,
                typingTime: 0,
                typeID: ChatItemType.ANIMATED_SVG,
                uuid: UUID.uuidv4()
            }, props.node),
            preview: props.node ? (isSvg(props.node.code) ? props.node.code : null) : null,
            sizeOptions: [{value: "small", label: "Small"},{value: "medium", label: "Medium"}, {value: "large", label: "Large"}],
            validated: false,
            errors: {},
            touched: {}
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);
        let preview = this.state.preview;

        if (name === "title") {
            item.nodeText = value;
        }

        if (name === "code") {
            if (value && value.length > 0 && isSvg(value)) {
                preview = value;
            }
            else {
                preview = null;
            }
        }

        if (name === "useCustomDisplayTime") {
            item.displayTime = 0;
        }

        if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        }

        item[name] = value;

        this.setState({
            item: item,
            preview: preview
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.code) {
            if (touched.code) {
                errors.code = "SVG Code is required";
            }
            validated = false;
        }
        else {
            if (!isSvg(item.code)) {
                errors.code = "Not a valid SVG code. The code must start and end with <svg></svg> tags.";
                validated = false;
            }
        }

        if (!item.title) {
            if (touched.title) {
                errors.title = "Title is required";
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }




    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat message</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">* Title</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.title && "error")}>
                                <input type="text" name="title" value={this.state.item.title} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.title}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="altText" className="text-right middle">Alt text</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <textarea rows={5} name="altText" value={this.state.item.altText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="field-info">Animation's description used by Voice Over reader in the app. If not set, title would be used instead.</small>
                                <small className="error">{this.state.errors["altText"]}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">* SVG Code</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.code && "error")}>
                                <textarea rows={5} name="code" value={this.state.item.code} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.code}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="size" className="text-right middle">* Size</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <ReactSelect
                                    name="size"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.size}
                                    options={this.state.sizeOptions}
                                />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">Preview</label>
                            </div>
                            <div className="large-9 small-9 cell" >
                                <div style={{maxWidth: 200}} dangerouslySetInnerHTML={{__html: this.state.preview}}></div>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomDisplayTime" className="text-right middle">Custom display time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomDisplayTime" onChange={this.handleChange}
                                           checked={this.state.item.useCustomDisplayTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomDisplayTime === 1 &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="displayTime" className="text-right middle">* Display Time</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.displayTime && "error")}>
                                    <input name="displayTime" type="number" value={this.state.item.displayTime} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.displayTime}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime" onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                    <input name="typingTime" type="number" value={this.state.item.typingTime} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.typingTime}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </React.Fragment>
        );
    }
}
