import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import Image from 'components/common/Image'
import Modal from "react-responsive-modal"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import FileInput from "../common/FileInput";
import TemplateFile from 'assets/files/podcast_asset_template.psd'
import {NotificationManager} from "react-notifications";
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";
import TranscriptForm from "./TranscriptForm";
import HabitsSelector from "../habits/HabitsSelector";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const PREVIEW_TEXT_MAX_LENGTH = 4000;

class PodcastForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: props.item && props.item.langID ? Object.assign({}, props.item) : Object.assign({
                langID: null,
                translations: [],
                bgMusic: props.item ? (props.item.musicFileURL ? 1 : 0) : 1,
                private: 1,
                habitID: null,
                habitTitle: null,
                haveNextSteps: props.item ? (props.item.nextSteps ? 1 : 0) : 0,
                organisationID: props.item.organisationID || context.organisation.id
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            updatedRawTranscriptContent: null,
            updatedRawNextStepsContent: null,
            validated: false,
            canEditNextSteps: true,
            errors: {},
            touched: {},
            bgImagePickerURL: null,
            bgImagePickerFile: null,
            headerImagePickerURL: null,
            headerImagePickerFile: null,
            smallImagePickerURL: null,
            smallImagePickerFile: null,
            audioFilePickerURL: null,
            audioFilePickerFile: null,
            isUploading: false,
            uploadProgress: 0,
            previewTextCharLeft: PREVIEW_TEXT_MAX_LENGTH,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        };

        if (this.state.item.organisationID === null) {
            this.state.item.organisationID = "-1";
        }

        //TODO
        // Temporary fix to prevent editing podcasts for next steps to which have editable forms
        // We don't have that functionality in content editor yet so we need to block it
        // if (this.state.item.nextSteps) {
        //     if (this.state.item.nextSteps.elements && Array.isArray(this.state.item.nextSteps.elements)) {
        //         this.state.item.nextSteps.elements.forEach((item) => {
        //             if (item.type === "button" && item.data && item.data.action && item.data.action.type && item.data.action.type === "submit") {
        //                 this.state.canEditNextSteps = false;
        //             }
        //             else if (item.type === "file") {
        //                 this.state.canEditNextSteps = false;
        //             }
        //         })
        //     }
        // }
    }

    componentDidMount() {

        if (this.state.item.langID === null) {
            this.validate();

            if (this.context.permissions.podcasts.allOrgs) {
                this.getOrganisationOptions();
            }

            if (this.state.item.id) {
                this.updatePreviewTextCounter(this.state.item.previewText);
            }

            if (this.state.item.id) {
                this.getTranslations();
            } else {
                this.getLanguages();
            }
        }


    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.podcast.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }


    getOrganisationOptions = () => {

        this.setState({
            isLoadingOrganisations: true
        });

        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let organisationOptions = res.data.data.organisations.map((item) => { return {value: item.id, label: item.name}});
                    organisationOptions.splice(0, 0, {value: "-1", label: "System Service"});

                    this.setState({
                        isLoadingOrganisation: false,
                        organisationOptions: organisationOptions
                    })
                }
                else {
                    this.setState({
                        isLoadingOrganisations: false
                    })
                }
            })
    }

    handleFileDelete = (name) => {
        if (name === "bgImageFile") {
            this.setState({
                bgImagePickerURL: null,
                bgImagePickerFile: null
            });
        }
        else if (name === "headerImageFile") {
            this.setState({
                headerImagePickerFile: null,
                headerImagePickerURL: null
            })
        }
        else if (name === "smallImageFile") {
            this.setState({
                smallImagePickerFile: null,
                smallImagePickerURL: null
            })
        }
        else if (name === "audioFile") {
            this.setState({
                audioFilePickerURL: null,
                audioFilePickerFile: null
            })
        }
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let name = event.target.name;

        let self = this;

        reader.onloadend = () => {

            let newState = {};

            if (name === "bgImageFile") {
                newState = {
                    bgImagePickerFile: file ? file : null,
                    bgImagePickerURL: file ? reader.result : null
                }
            }
            else if (name === "headerImageFile") {
                newState = {
                    headerImagePickerFile: file ? file : null,
                    headerImagePickerURL: file ? reader.result : null
                }
            }
            else if (name === "smallImageFile") {
                newState = {
                    smallImagePickerFile: file ? file : null,
                    smallImagePickerURL: file ? reader.result : null
                }
            }
            else if (name === "audioFile") {
                newState = {
                    audioFilePickerFile: file ? file : null,
                    audioFilePickerURL: file ? reader.result : null
                }
            }

            self.setState(newState, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {

                if (this.state.item.langID === null) {

                    let formData = new FormData();

                    if (this.state.item.id) {
                        formData.append("id", this.state.item.id);
                    }

                    formData.append("title", this.state.item.title);
                    formData.append("categoryTitle", this.state.item.categoryTitle);
                    formData.append("bgMusic", this.state.item.bgMusic);
                    formData.append("previewText", this.state.item.previewText);
                    formData.append("haveNextSteps", this.state.item.haveNextSteps);

                    if (this.state.item.habitID) {
                        formData.append("habitID", this.state.item.habitID);
                    }

                    if (this.state.item.organisationID !== "-1") {
                        formData.append("organisationID", this.state.item.organisationID);
                    }

                    let hasFile = false;

                    if (this.state.audioFilePickerFile) {
                        formData.append("audioFile", this.state.audioFilePickerFile);
                        hasFile = true;
                    }

                    if (this.state.bgImagePickerFile) {
                        formData.append("bgImage", this.state.bgImagePickerFile);
                        hasFile = true;
                    }

                    if (this.state.headerImagePickerFile) {
                        formData.append("headerImage", this.state.headerImagePickerFile);
                        hasFile = true;
                    }

                    if (this.state.smallImagePickerFile) {
                        formData.append("smallImage", this.state.smallImagePickerFile);
                        hasFile = true;
                    }


                    if (this.state.updatedRawTranscriptContent) {

                        let elements = Array.from(this.state.updatedRawTranscriptContent.elements);

                        elements.forEach((element, index, array) => {
                            if (element.type === "image" && element.imagePickerFile) {
                                hasFile = true;
                                formData.append(element.id, element.imagePickerFile);

                                elements[index] = {
                                    type: element.type,
                                    id: element.id,
                                    data: {
                                        aspectRatio: element.data.aspectRatio,
                                        contentMode: element.data.contentMode
                                    }
                                }
                            }
                        });

                        let itemData = {};
                        itemData.defaults = {};
                        itemData.defaults.style = Object.assign({}, this.state.updatedRawTranscriptContent.defaults);
                        itemData.elements = elements;
                        const json = JSON.stringify(itemData);
                        formData.append("rawTranscript", json);

                    }

                    if (this.state.updatedRawNextStepsContent) {

                        let elements = Array.from(this.state.updatedRawNextStepsContent.elements);

                        elements.forEach((element, index, array) => {
                            if (element.type === "image" && element.imagePickerFile) {
                                hasFile = true;
                                formData.append(element.id, element.imagePickerFile);

                                elements[index] = {
                                    type: element.type,
                                    id: element.id,
                                    data: {
                                        aspectRatio: element.data.aspectRatio,
                                        contentMode: element.data.contentMode
                                    }
                                }
                            }
                        });

                        let itemData = {};
                        itemData.defaults = {};
                        itemData.defaults.style = Object.assign({}, this.state.updatedRawNextStepsContent.defaults);
                        itemData.elements = elements;
                        const json = JSON.stringify(itemData);
                        formData.append("rawNextSteps", json);

                    }

                    Axios
                        .post((this.state.item.id ? API.podcast.updatePodcast : API.podcast.createPodcast), formData, {
                            headers: {
                                'Content-Type': hasFile ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                            }
                        })
                        .then((res) => {
                            if (res.data.success) {
                                this.props.onSubmitted(res.data.data.item);
                            }
                            else if (res.data.error) {
                                let errors = this.state.errors;
                                errors.genericError = res.data.error.desc;
                                this.setState({
                                    errors: errors
                                });
                            }

                            this.setState({
                                isUploading: false
                            });
                        })

                }

                else {

                    let formData = new FormData();

                    formData.append("id", this.props.parentID);
                    formData.append("langID", this.state.item.langID);
                    formData.append("title", this.state.item.title);
                    formData.append("categoryTitle", this.state.item.categoryTitle);
                    formData.append("previewText", this.state.item.previewText);
                    formData.append("haveNextSteps", this.state.item.haveNextSteps);

                    let hasFile = false;

                    if (this.state.audioFilePickerFile) {
                        formData.append("audioFile", this.state.audioFilePickerFile);
                        hasFile = true;
                    }


                    if (this.state.updatedRawTranscriptContent) {

                        let elements = Array.from(this.state.updatedRawTranscriptContent.elements);

                        elements.forEach((element, index, array) => {
                            if (element.type === "image" && element.imagePickerFile) {
                                hasFile = true;
                                formData.append(element.id, element.imagePickerFile);

                                elements[index] = {
                                    type: element.type,
                                    id: element.id,
                                    data: {
                                        aspectRatio: element.data.aspectRatio,
                                        contentMode: element.data.contentMode
                                    }
                                }
                            }
                        });

                        let itemData = {};
                        itemData.defaults = {};
                        itemData.defaults.style = Object.assign({}, this.state.updatedRawTranscriptContent.defaults);
                        itemData.elements = elements;
                        const json = JSON.stringify(itemData);
                        formData.append("rawTranscript", json);
                    }

                    if (this.state.updatedRawNextStepsContent) {

                        let elements = Array.from(this.state.updatedRawNextStepsContent.elements);

                        elements.forEach((element, index, array) => {
                            if (element.type === "image" && element.imagePickerFile) {
                                hasFile = true;
                                formData.append(element.id, element.imagePickerFile);

                                elements[index] = {
                                    type: element.type,
                                    id: element.id,
                                    data: {
                                        aspectRatio: element.data.aspectRatio,
                                        contentMode: element.data.contentMode
                                    }
                                }
                            }
                        });

                        let itemData = {};
                        itemData.defaults = {};
                        itemData.defaults.style = Object.assign({}, this.state.updatedRawNextStepsContent.defaults);
                        itemData.elements = elements;
                        const json = JSON.stringify(itemData);
                        formData.append("rawNextSteps", json);

                    }

                    Axios
                        .post(API.podcast.updateTranslation, formData, {
                            headers: {
                                'Content-Type': hasFile ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                            }
                        })
                        .then((res) => {
                            if (res.data.success) {
                                NotificationManager.success("Saved successfully");
                            }
                            else if (res.data.error) {
                                NotificationManager.error(res.data.error.desc);
                            }

                            this.setState({
                                isUploading: false
                            }, () => {
                                this.props.onCompleted(res.data.success ? res.data.data.podcastTranslation : this.state.item);
                            });
                        })

                }


            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }
        else {
            if (name === "previewText") {
                if (value.length > PREVIEW_TEXT_MAX_LENGTH) {
                    value = value.substring(0, PREVIEW_TEXT_MAX_LENGTH - 1);
                }
                this.updatePreviewTextCounter(value);
            }

            let item = Object.assign({}, this.state.item);
            item[name] = value;
            newState.item = item;
        }



        this.setState(newState, () => {
            this.validate();
        });
    }

    updatePreviewTextCounter = (value) => {
        if (value.length > PREVIEW_TEXT_MAX_LENGTH) {
            value = value.substring(0, PREVIEW_TEXT_MAX_LENGTH - 1);
        }

        this.setState({
            previewTextCharLeft: PREVIEW_TEXT_MAX_LENGTH - value.length
        })
    }


    validate = () => {

        if (this.state.item.langID !== null) { return; }

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.title) {
            if (touched.title) {
                errors.title = "Title is required";
            }
            validated = false;
        }

        if (!item.categoryTitle) {
            if (touched.categoryTitle) {
                errors.categoryTitle = "Category title is required"
            }
            validated = false;
        }

        if (!this.state.audioFilePickerFile && !item.audioFileURL) {
            if (touched.audioFilePickerFile) {
                errors.audioFilePickerFile = "Audio file is required"
            }
            validated = false;
        }

        if (!this.state.bgImagePickerFile && !item.bgImageURL) {
            if (touched.bgImagePickerFile) {
                errors.bgImagePickerFile = "Background image is required"
            }
            validated = false;
        }

        if (!this.state.headerImagePickerFile && !item.headerImageURL) {
            if (touched.headerImagePickerFile) {
                errors.headerImagePickerFile = "Header image is required"
            }
            validated = false;
        }

        if (!this.state.smallImagePickerFile && !item.smallImageURL) {
            if (touched.smallImagePickerFile) {
                errors.smallImagePickerFile = "Icon image is required"
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }


    saveTranscriptChanges = (rawContent) => {
        this.closeModal(() => {
            this.setState({
                updatedRawTranscriptContent: rawContent
            })
        });
    }

    saveNextStepsChanges = (rawContent) => {
        this.closeModal(() => {
            this.setState({
                updatedRawNextStepsContent: rawContent
            })
        })
    }


    showDynamicPageEditor = (rawContent, content, onSave, showFormElements, labelsOnly) => {
        this.setState({
            modal: {
                isOpen: true,
                content: (
                    <TranscriptForm
                        rawContent={rawContent}
                        content={content}
                        onCancel={this.closeModal}
                        onSave={onSave}
                        showFormElements={showFormElements}
                        labelsOnly={labelsOnly}
                    />
                ),
                size: "modal-large"
            }
        })
    }


    showNextStepsModal = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        this.showDynamicPageEditor(this.state.updatedRawNextStepsContent, this.state.item.nextSteps, this.saveNextStepsChanges, true, false);
    }


    showTranscriptModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        this.showDynamicPageEditor(this.state.updatedRawTranscriptContent, this.state.item.transcript, this.saveTranscriptChanges, false, true);
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    size: "modal-medium"
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showHabitModal = (habitID) => {
        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (habitID) {
            selected.push({id: habitID})
        }

        const content = (
            <HabitsSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(habit) => {

                    let item = Object.assign({}, this.state.item);
                    item.habitID = habit.id;
                    item.habitTitle = habit.name;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }

    removeHabit = () => {

        let item = Object.assign({}, this.state.item);
        item.habitID = null;
        item.habitTitle = null;

        this.setState({
            item: item
        }, () => {
            this.validate();
        })
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            title: "",
            categoryTitle: "",
            previewText: "",
            haveNextSteps: this.state.item.haveNextSteps,
            id: UUID.uuidv4(),
            isNew: 1
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <PodcastForm
                    parentID={this.state.item.id}
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.setState(prevState => ({
                            languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                            item: {
                                ...prevState.item,
                                translations: [...prevState.item.translations, updatedTranslation]
                            }
                        }), () => {
                            this.closeModal();
                        });
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                selectedLanguage: null,
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <PodcastForm
                    parentID={this.state.item.id}
                    item={{
                        ...translation,
                        haveNextSteps: this.state.item.haveNextSteps
                    }}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.deleteTranslation(translation);

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })

    }

    deleteTranslation = (translation) => {
        Axios
            .post(API.podcast.deleteTranslation, {
                id: this.state.item.id,
                langID: translation.langID
            })
            .then((res) => {

                if (res.data.success) {
                    NotificationManager.success("Deleted successfully");
                }
                else if (res.data.error) {
                    NotificationManager.error(res.data.error.desc);
                }
            })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }

    render() {

        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    {this.state.item.langID === null ?
                        <h3>{this.state.item.id ? "Edit Podcast" : "New Podcast"}</h3>
                        :
                        <h3>Podcast ({this.state.item.langName} translation)</h3>
                    }
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        {this.state.item.langID === null && this.context.permissions.podcasts.allOrgs &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                <ReactSelect
                                    name="organisationID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.organisationID}
                                    options={this.state.organisationOptions}
                                    onBlur={this.handleBlur}
                                />
                                <small className="field-info">System level podcasts are visible to all organisations</small>
                                <small className="error">{this.state.errors.organisationID}</small>
                            </div>
                        </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="title" className="text-right middle ">{this.state.item.langID === null ? "*" : ""} Title</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.title && "error")}>
                                <input type="text" name="title" onChange={this.handleChange} value={this.state.item.title} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.title}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="categoryTitle" className="text-right middle ">{this.state.item.langID === null ? "*" : ""} Category Title</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.categoryTitle && "error")}>
                                <input type="text" name="categoryTitle" onChange={this.handleChange} value={this.state.item.categoryTitle} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.categoryTitle}</small>
                            </div>
                        </div>
                        {this.state.item.langID === null &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="habitTitle" className="text-right middle">Associated Habit</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.habitTitle && "error")}>
                                <div className="input-group">
                                    <input className="input-group-field" name="habitTitle" type="text" value={this.state.item.habitTitle || "Not selected" } disabled/>
                                    <div className="input-group-button">
                                        {this.state.item.habitID &&
                                        <button type="button" className="button alert" onClick={() => this.removeHabit()}>Remove</button>
                                        }
                                        <button type="button" className="button" onClick={() => this.showHabitModal(this.state.item.habitID)}>Browse</button>
                                    </div>
                                </div>
                                <small className="error">{this.state.errors.habitTitle}</small>
                                <small className="field-info">Associated habit will be added to users habit logs every time the podcast is listened too. If the associated habit's primary unit is set to seconds, habit log would also display listening time.</small>
                            </div>
                        </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="previewText" className="text-right middle">{this.state.item.langID === null ? "*" : ""} Preview Text</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.previewText && "error")}>
                                <textarea rows="6" type="text" name="previewText" onChange={this.handleChange} value={this.state.item.previewText} onBlur={this.handleBlur} />
                                <small className="field-info">{this.state.previewTextCharLeft} characters left</small>
                                <small className="error">{this.state.errors.previewText}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="audioFile" className="text-right middle">Select Audio File</label>
                            </div>
                            <div className="large-9 small-9 cell ">
                                <FileInput
                                    name="audioFile"
                                    type="file"
                                    accept=".mp3"
                                    placeholder="Not selected. Max size 50MB. MP3 format only."
                                    onChange={this.handleFilePickerChange}
                                    onBlur={this.handleBlur}
                                    onDelete={this.handleFileDelete}
                                />
                                <small className="field-info">
                                    Adobe audition is advised to compress the podcast, however other alternatives will work.<br/>
                                    Recommended settings:<br/>
                                    Format: MP3 Audio (*.mp3)<br/>
                                    Sample Type: 44100Hz Mono, 16-bit<br/>
                                    Format Settings: MP3 64kbps CBR (Constant Bit Rate)<br/>
                                    Do Not include markers, images or any other meta data<br/>
                                </small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="audioFilePickerFile" className="text-right middle">{this.state.item.langID === null ? "*" : ""} Current Audio File</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.audioFile && "error")} style={{marginBottom: 20}}>
                                {(this.state.audioFilePickerURL || this.state.item.audioFileURL) ?
                                    <AudioPlayer
                                        src={this.state.audioFilePickerURL || this.state.item.audioFileURL}
                                        showJumpControls={false}
                                        customAdditionalControls={[]}
                                    />
                                    :
                                    <p>No audio file selected</p>
                                }
                                <small className="error">{this.state.errors.audioFilePickerFile}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="transcript" className="text-right middle">Transcript</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.transcript && "error")} style={{marginBottom: 20}}>
                                <button className="button primary" type="button" onClick={this.showTranscriptModal}>Edit</button>
                                <small className="error">{this.state.errors.transcript}</small>
                            </div>
                        </div>
                        {!this.state.canEditNextSteps &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="nextSteps" className="text-right middle">Next Steps</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.nextSteps && "error")}
                                 style={{marginBottom: 20}}>
                                Next steps cannot be edited for this podcast.
                            </div>
                        </div>
                        }
                        {this.state.item.langID === null && this.state.canEditNextSteps &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="haveNextSteps" className="text-right middle">Have next steps?</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.haveNextSteps && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="haveNextSteps" onChange={this.handleChange} checked={this.state.item.haveNextSteps === 1} onBlur={this.handleBlur} />
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.haveNextSteps}</small>
                                </label>
                            </div>
                        </div>
                        }
                        {this.state.canEditNextSteps && this.state.item.haveNextSteps === 1 &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="nextSteps" className="text-right middle">Next Steps</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.nextSteps && "error")}
                                 style={{marginBottom: 20}}>
                                <button className="button primary" type="button"
                                        onClick={this.showNextStepsModal}>Edit
                                </button>
                                <small className="error">{this.state.errors.nextSteps}</small>
                            </div>
                        </div>
                        }
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Images</legend>
                                        <div className="fieldset-info">
                                            <h5>How to prepare podcast images?</h5>
                                            <p><small><strong>Please download our <a style={{color: "#1779ba"}} href={TemplateFile}> Adobe Photoshop template </a> and follow the instructions below:</strong></small></p>
                                            <p><small>There are three artboards contained within the podcast asset template.psd that are used to create the 3 necessary assets for a podcast.</small></p>
                                            <p><small>At the bottom layer of each of the three artboards, there is a smart object layer named ‘insert picture here’. The image intended for use as for the assets for the podcast can be inserted in or over this smart object layer. Any overlay layers cannot be altered.</small></p>
                                            <p><small>Once the asset has been set up, the assets should be exported by navigating to: <br /> File > Export > Export As</small></p>
                                            <p><small>When the ‘Export As’ window is open, the scales of the assets should not be changed and no multiple size options should be set up. The scales section should simply show Size = 1x and Suffix to be blank. The format of the images should be JPG and the quality to 60%.</small></p>
                                            <p><small>Once these parameters have been set then the images can be exported by clicking ‘Export’.</small></p>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="bgImageFile" className="text-right middle">Select Background Image</label>
                                            </div>
                                            <div className="large-9 small-9 cell ">
                                                <FileInput
                                                    name="bgImageFile"
                                                    onChange={this.handleFilePickerChange}
                                                    onBlur={this.handleBlur}
                                                    onDelete={this.handleFileDelete}
                                                    placeholder="Not selected. Ideal size W:2436px H:1242px (jpg)"
                                                    accept="image/jpeg,image/jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="bgImageFile" className="text-right middle">* Current Background Image</label>
                                            </div>
                                            <div className={"large-9 small-9 cell " + (this.state.errors.bgImageFile && "error")} style={{marginBottom: 20}}>
                                                {this.state.bgImagePickerURL &&
                                                <Image src={this.state.bgImagePickerURL} width={200} height={433} border={true} />
                                                }
                                                {!this.state.bgImagePickerURL && this.state.item.bgImageURL &&
                                                <Image src={this.state.item.bgImageURL} width={200} height={433} border={true} />
                                                }
                                                {!this.state.bgImagePickerURL && !this.state.item.bgImageURL &&
                                                <p>No image selected</p>
                                                }
                                                <small className="error">{this.state.errors.bgImageFile}</small>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="headerImageFile" className="text-right middle">Select Header Image</label>
                                            </div>
                                            <div className="large-9 small-9 cell ">
                                                <FileInput
                                                    name="headerImageFile"
                                                    onChange={this.handleFilePickerChange}
                                                    onBlur={this.handleBlur}
                                                    onDelete={this.handleFileDelete}
                                                    placeholder="Not selected. Ideal size W:720px H:442px (jpg). White gradient applied."
                                                    accept="image/jpeg,image/jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="headerImageFile" className="text-right middle">* Current Header Image</label>
                                            </div>
                                            <div className={"large-9 small-9 cell " + (this.state.errors.headerImageFile && "error")} style={{marginBottom: 20}}>
                                                {this.state.headerImagePickerURL &&
                                                <Image src={this.state.headerImagePickerURL} width={200} height={120} border={true} />
                                                }
                                                {!this.state.headerImagePickerURL && this.state.item.headerImageURL &&
                                                <Image src={this.state.item.headerImageURL} width={200} height={120} border={true} />
                                                }
                                                {!this.state.headerImagePickerURL && !this.state.item.headerImageURL &&
                                                <p>No image selected</p>
                                                }
                                                <small className="error">{this.state.errors.headerImageFile}</small>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="smallImageFile" className="text-right middle">Select On-boarding Image</label>
                                            </div>
                                            <div className="large-9 small-9 cell ">
                                                <FileInput
                                                    name="smallImageFile"
                                                    onChange={this.handleFilePickerChange}
                                                    onBlur={this.handleBlur}
                                                    onDelete={this.handleFileDelete}
                                                    placeholder="Not selected. Ideal size W:297px H:177px (jpg)"
                                                    accept="image/jpeg,image/jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="smallImageFile" className="text-right middle">* Current On-boarding Image</label>
                                            </div>
                                            <div className={"large-9 small-9 cell " + (this.state.errors.smallImageFile && "error")} style={{marginBottom: 20}}>
                                                {this.state.smallImagePickerURL &&
                                                <Image src={this.state.smallImagePickerURL} width={80} height={48} border={true} />
                                                }
                                                {!this.state.smallImagePickerURL && this.state.item.smallImageURL &&
                                                <Image src={this.state.item.smallImageURL} width={80} height={48} border={true} />
                                                }
                                                {!this.state.smallImagePickerURL && !this.state.item.smallImageURL &&
                                                <p>No image selected</p>
                                                }
                                                <small className="error">{this.state.errors.smallImageFile}</small>
                                            </div>
                                        </div>
                                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                                            <div className="cell small-12">
                                                <span>{this.state.errors.genericError}</span>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Translations</legend>
                                        {this.state.item.id ?
                                        <React.Fragment>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <span>Add translation for</span>
                                                    <ReactSelect
                                                        name="selectedLanguage"
                                                        className="react-select translation-selector"
                                                        onChange={this.handleChange}
                                                        value={this.state.selectedLanguage}
                                                        options={this.state.languageOptions}
                                                        onBlur={this.handleBlur}
                                                    />
                                                    <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                                </div>
                                            </div>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: 'Language',
                                                                accessor: 'langName'
                                                            },
                                                            {
                                                                Header: 'Actions',
                                                                Cell: row => (
                                                                    <ButtonGroup size={Sizes.TINY}>
                                                                        {this.context.permissions.campus.edit &&
                                                                        <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                        }
                                                                        {this.context.permissions.campus.delete &&
                                                                        <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                        }
                                                                    </ButtonGroup>
                                                                ),
                                                                maxWidth: 100,
                                                                show: this.context.permissions.campus.delete || this.context.permissions.campus.edit
                                                            }
                                                        ]}
                                                        data={this.state.item.translations}
                                                        className='-striped'
                                                        showPagination={false}
                                                        noDataText={"No translations"}
                                                        pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                            :
                                        <React.Fragment>
                                            <p>Translations for Podcasts can only be added once the Podcast is created. Please submit the Podcast first and then use editing functionality to add additional language variations.</p>
                                        </React.Fragment>
                                        }
                                    </fieldset>
                                </div>
                            </div>
                        </React.Fragment>

                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={this.state.item.langID === null && !this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    organisationID={this.state.item.organisationID}
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="PodcastInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default PodcastForm;
