import React from 'react'
import { Redirect } from 'react-router-dom'
import AppComponent from 'components/common/AppComponent'
import PageContent from 'components/common/PageContent'
import {changeNodeAtPath, removeNodeAtPath, getVisibleNodeCount, getNodeAtPath} from 'react-sortable-tree'
import SortableTree from 'react-sortable-tree'
import Axios from 'axios'
import { API } from 'api/APIClient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import TreeNodeInput from 'components/common/TreeNodeInput'
import SubmenuForm from 'components/common/SubmenuForm'
import ServicesSelector from 'components/services/ServicesSelector'
import Modal from 'react-responsive-modal'
import ReactSelect from 'components/common/ReactSelect'
import SectionHeaderForm from 'components/common/SectionHeaderForm'
import { SectionType } from 'constants/SectionType'
import BlockUi from 'react-block-ui'
import {ServiceType} from "../../constants/ServiceType";
import {SubmenuLayoutType} from "../../constants/SubmenuLayoutType";
import Image from "components/common/Image";
import placeholder from "assets/img/placeholder.png"
import ServiceAccessImageForm from "components/sectionConfig/ServiceAccessImageForm"
import UUID from 'utils/UUID'
import {NotificationManager} from "react-notifications";
import StandardModal from "../common/StandardModal";
import Utils from "../../utils/Utils";
import ReactTable from "react-table";


const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Section Editor"}
];


export default class SectionEditor extends AppComponent {

    treeObject = {};

    constructor(props, context) {
        super(props);

        let item = this.props.item ? this.props.item : Utils.getNested(props, "location", "state", "item");

        this.state = {
            item: item && item.langID ? Object.assign({}, item) : Object.assign({
                langID: null,
                translations: []
            }, item),
            orgTreeData: null,
            organisationID: item && item.langID === null ? props.location.state.item.organisationID : context.organisation.id,
            organisationOptions: item && item.langID === null ? [{value: props.location.state.item.organisationID, label: props.location.state.item.organisationName}] : [{value: context.organisation.id, label: context.organisation.name}],
            treeData: item && item.treeData || [],
            touched: [],
            errors: [],
            modal: {
                isOpen: false,
                content: null,
                size: "modal-small"
            },
            roleOptions: [],
            campusOptions: [],
            visibility: props.item ? [] : [{campus: null, roles: []}],
            isUploading: false,
            validated: false,
            redirect: false,
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        };

    }



    componentDidMount() {
        if (this.state.item.langID === null) {
            if (this.props.location.state.item) {
                this.fetchStructure();
                this.getTranslations();
            }
            else {
                this.getLanguages();
                this.getAvailableAccessLevels();
            }
            if (!this.props.location.state.item && this.props.location.state.permissions.allOrgs) {
                this.getOrganisationOptions();
            }

            this.validate();
        }
        else {
            //TODO
        }
    }


    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.sectionConfig.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {

                        let translations = res.data.data.translations.map((o) => {
                            let obj = Object.assign({}, o);
                            obj.treeData = this.parseTreeDataResponse(o.structure);
                            return obj;
                        });

                        debugger;


                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }


    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id, label: item.name} })
                    })
                }
            })
    }

    fetchStructure = () => {
        Axios
            .get(API.sectionConfig.getConfigStructure, {
                params: {
                    id: this.props.location.state.item.id
                }
            })
            .then((res) => {
                if (res.data.success) {

                    const treeData = this.parseTreeDataResponse(res.data.data.treeData);
                    const options = this.parseAvailableOptionsResponse(res.data.data.availableOptions);
                    const visibility = this.parseVisibility(res.data.data.visibility);


                    console.log(treeData);

                    this.setState({
                        name: res.data.data.config.name,
                        organisationID: res.data.data.config.organisationID,
                        treeData: treeData,
                        campusOptions: options.campusOptions,
                        roleOptions: options.roleOptions,
                        visibility: visibility
                    })
                }
                this.validate();
            })
    }

    parseVisibility = (res) => {

        let visibility = [];

        Object.keys(res).forEach(k => {
            visibility.push({
                campus: parseInt(k),
                roles: res[k]
            })
        });

        return visibility;
    }

    parseTreeDataResponse = (tree) => {

        let treeData = Array.from(tree);

        treeData.forEach(o => {
            o["titleText"] = o.title;
            o["titleOrg"] = o.title;
            delete o.title;
            o["subtitleText"] = o.subtitle;
            o["subtitleOrg"] = o.subtitle;
            delete o.subtitle;
            o["typeText"] = o.typeTitle;
            o["typeOrg"] = o.typeTitle;
            delete o.typeTitle;
            o["dataOrg"] = o.data;
            if (o.children instanceof Array) {
                this.parseTreeDataResponse(o.children);
            }
        });

        return treeData;
    }

    parseAvailableOptionsResponse = (res) => {
        let campusOptions = [];
        let roleOptions = {};

        res.forEach(o => {
            campusOptions.push({
                value: o.campus.id,
                label: o.campus.name
            });

            roleOptions[o.campus.id] = o.roles.map((r) => { return {
                value: r.id,
                label: r.name
            }});
        });

        return {
            campusOptions: campusOptions,
            roleOptions: roleOptions
        }
    }

    getAvailableAccessLevels = () => {
        Axios
            .get(API.sectionConfig.getAvailableAccessLevels, {
                params: {
                    organisationID: this.state.organisationID,
                    sectionTypeID: this.props.location.state.sectionType
                }
            })
            .then((res) => {
                if (res.data.success) {
                    const options = this.parseAvailableOptionsResponse(res.data.data.availableOptions);

                    this.setState({
                        campusOptions: options.campusOptions,
                        roleOptions: options.roleOptions
                    });
                }
            })
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let newState = {};

        if (name === "name") {

            let item = Object.assign({}, this.state.item);
            item[name] = value;
            newState.item = item;
        }
        else if (name === "selectedLanguage") {
            newState.selectedLanguage = value;
        }
        else if (name === "organisationID") {
            newState.organisationID = value;
            newState.campusOptions = [];
            newState.roleOptions = [];
            newState.visibility = [{campus: null, roles: []}]
            newState.treeData = [];
            newState.name = "";
            newState.touched = [];
            newState.errors = [];
            newState.validated = false;
        }

        this.setState(newState, () => {
            this.validate();
            if (name === "organisationID") {
                this.getAvailableAccessLevels();
            }
        });
    }

    handleSubmit = () => {

        if (!this.state.isUploading) {

            this.setState({
                isUploading: true
            }, () => {

                let data = {
                    name: this.state.item.name,
                    sectionTypeID: this.props.location.state.sectionType,
                    organisationID: this.state.organisationID,
                    structure: this.buildStructureTreePayload(this.state.treeData),
                    access: this.buildAccessPayload(this.state.visibility),
                    translations: this.state.item.translations.map((o) => {
                        return {
                            langID: o.langID,
                            langName: o.langName,
                            structure: this.buildStructureTreePayload(o.treeData)
                        }
                    })
                }

                if (this.props.location.state.item) {
                    data.configID = this.props.location.state.item.id
                }

                Axios
                    .post(API.sectionConfig.createSectionConfig, data)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isUploading: false,
                                redirect: true
                            });
                        }
                        else {
                            this.setState({
                                isUploading: false
                            });
                        }
                    })
            })
        }
    }

    buildAccessPayload = (visibility) => {
        let access = [];

        visibility.forEach(v => {
            if (v.campus && v.roles.length > 0) {
                access.push({
                    campusID: v.campus,
                    roles: v.roles
                });
            }
        });

        return access;
    }

    buildStructureTreePayload = (treeData) => {

        let structure = [];

        treeData.forEach(s => {
            let element = {
                id: s.id.toString(),
                title: s.titleText,
                subtitle: s.subtitleText,
                typeTitle: s.typeText,
                serviceID: s.serviceID || null,
                imageID: s.imageID || null,
                data: s.data || null,
                typeID: s.typeID || null,
                hideImage: s.hideImage,
                isSectionHeader: s.isSectionHeader === true ? 1 : 0
            };

            if (!s.typeID && s.children) {
                element.children = this.buildStructureTreePayload(s.children);
            }

            structure.push(element);
        });

        return structure;
    }

    showAddService = () => {

        const content = (
            <ServicesSelector
                organisationID={this.state.organisationID}
                onSubmitted={(orgItems) => {

                    let items = Array.from(orgItems);
                    items.forEach(i => {
                        i.titleText = i.title;
                        i.titleOrg = i.title;
                        i.subtitleText = i.subtitle;
                        i.subtitleOrg = i.subtitle;
                        i.serviceID = i.id;
                        i.serviceImageURL = i.imageURL;
                        i.hideImage = 0;
                        i.serviceImageID = i.imageID;
                        i.typeText = i.typeTitle || ServiceType.getTitle(i.typeID);
                        delete i.id;
                        delete i.title;
                        delete i.subtitle;
                        i.id = UUID.uuidv4();
                        i.imageURL = null;
                        i.imageID = null;
                    })

                    let treeData = Array.from(this.state.treeData);
                    treeData.unshift(...items);
                    this.setState({
                        treeData: treeData,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    showAddSubmenu = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.treeData,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <SubmenuForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.treeData,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.treeData);
                        treeData.unshift(item);
                    }

                    this.setState({
                        treeData: treeData,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    showAddSectionHeader = () => {
        const content = (
            <SectionHeaderForm
                onSubmitted={(item) => {
                    let treeData = Array.from(this.state.treeData);
                    treeData.unshift(item);
                    this.setState({
                        treeData: treeData,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    showEditImageModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = getNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey
        }).node;

        console.log(node);

        const content = (
            <ServiceAccessImageForm
                item={node}
                onSubmitted={(item) => {
                    console.log(item);
                    delete item.subtitle;
                    let treeData = changeNodeAtPath({
                        treeData: this.state.treeData,
                        path,
                        newNode: item,
                        getNodeKey
                    })

                    this.setState({
                        treeData: treeData,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }


    handleVisibilityChange = (event, item) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let itemIndex = this.state.visibility.findIndex(i => i === item);

        if (itemIndex > -1) {

            let visibility = this.state.visibility;
            let newItem = Object.assign({}, item);

            if (name === "campuses") {

                newItem.campus = value;
                newItem.roles = [];
                visibility[itemIndex] = newItem;

                this.setState({
                    visibility: visibility
                }, () => {
                    this.validate();
                });
            }
            else if (name === "roles") {

                newItem.roles = value;
                visibility[itemIndex] = newItem;

                this.setState({
                    visibility: visibility
                }, () => {
                    this.validate();
                });

            }
        }
    }

    addVisibilityVariation = () => {

        let visibility = this.state.visibility;
        visibility.push({
            campus: null,
            roles: []
        });

        this.setState({
            visibility: visibility
        });
    }

    removeVisibilityVariation = (item) => {

        let visibility = this.state.visibility;

        const index = visibility.findIndex((i) => i === item);
        if (index > -1) {
            visibility.splice(index, 1);
        }

        this.setState({
            visibility: visibility
        }, () => {
            this.validate();
        });
    }


    removeAll = () => {
        this.setState({
            treeData: []
        });
    }

    revertChanges = () => {
        this.setState({
            treeData: this.state.orgTreeData
        });
    }

    closeModal = () => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            });
        }
    }

    validate = () => {

        let validated = true;
        const touched = this.state.touched;
        let errors = this.state.errors;

        if (!this.state.item.name) {
            if (touched.name) {
                errors = "Configuration name is required"
            }
            validated = false;
        }

        if (!this.state.visibility || !this.state.visibility.find(o => o.roles.length > 0)) {
            if (touched.visibility) {
                errors = "At least one role must be set in the visibility"
            }
            validated = false;
        }

        if (this.state.treeData.length === 0) {
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleImageClick = () => {
        console.log("image clicked");
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            id: UUID.uuidv4(),
            treeData: this.state.treeData,
            isNew: 1
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <SectionEditor
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                selectedLanguage: null,
                item: {
                    ...prevState.item,
                    translations: [...prevState.item.translations, translation]
                },
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <SectionEditor
                    item={this.updateTranslationStructure(translation, this.state.treeData)}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }

    updateTranslationStructure = (translation, mainTreeData) => {

        let flattenData = {};
        let treeData = mainTreeData.map((i) => {
            return Object.assign({}, i);
        });

        function flatten(s, ret) {

            if (Array.isArray(s)) {
                s.forEach((o) => {
                    if (o.hasOwnProperty("children")) {
                        flatten(o.children, {});
                    }

                    delete o.children;
                    flattenData[o.id.toString()] = o;
                })
            }
        }

        flatten(translation.treeData, {});

        function update(data, transData) {

            for(var i=0; i<data.length; i++) {
                if (data[i].hasOwnProperty("id") && transData.hasOwnProperty(data[i].id.toString())) {
                    const transElement = transData[data[i].id.toString()];
                    data[i].titleText = transElement.titleText ?? "";
                    data[i].subtitleText = transElement.subtitleText ?? "";
                    data[i].typeText = transElement.typeText ?? "";
                }

                if (data[i].hasOwnProperty("children") && Array.isArray(data[i].children)) {
                    update(data[i].children, transData);
                }
            }
        }

        debugger;

        update(treeData, flattenData);

        return {
            langID: translation.langID,
            langName: translation.langName,
            treeData: treeData
        }
    }

    handleTranslationSubmit = () => {
        let item = Object.assign({}, this.state.item, {
            treeData: this.state.treeData
        });
        this.props.onCompleted(item);
    }

    render() {

        if (this.state.redirect) {
            return (<Redirect to={SectionType.getPath(this.props.location.state.sectionType)} />);
        }

        const getNodeKey = ({ treeIndex }) => treeIndex;
        const nodeHeight = 90;
        const nodesCount = getVisibleNodeCount({treeData:this.state.treeData});

        let editorContent = (
            <div className="grid-x grid-padding-x">
                <div className="small-12 cell">
                    <fieldset className="fieldset">
                        <legend>Structure</legend>
                        {this.state.item.langID === null &&
                        <div className="grid-x grid-padding-x">
                            <div className="cell small-6">
                                <ButtonGroup className="float-left">
                                    <Button onClick={() => this.showAddSubmenu(null)}>Add Submenu</Button>
                                    <Button onClick={this.showAddSectionHeader}>Add Section Divider</Button>
                                    <Button onClick={this.showAddService}>Add Service</Button>
                                </ButtonGroup>
                            </div>
                            <div className="cell small-6">
                                <ButtonGroup className="float-right">
                                    <Button color={Colors.ALERT} onClick={this.removeAll} >Remove all</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="cell small-12">
                                {this.state.treeData.length > 0 ?
                                    <SortableTree
                                        ref={input => this.treeObject = input}
                                        style={{height: nodeHeight * nodesCount + 50}}
                                        treeData={this.state.treeData}
                                        rowHeight={nodeHeight}
                                        onChange={treeData => this.setState({treeData})}
                                        canDrop={({nextParent}) => !nextParent || !nextParent.noChildren}
                                        generateNodeProps={({node, path}) => ({
                                            class: ServiceType.getNodeClass(node.typeID),
                                            title: node.isSectionHeader ?
                                                <div className="tree-node">
                                                    <p className="type">Section divider</p>
                                                    <TreeNodeInput
                                                        value={node.titleText}
                                                        className="section-title"
                                                        placeholder="No title"
                                                        onChange={titleText => {
                                                            if (!titleText.length) {
                                                                return;
                                                            }
                                                            this.setState(state => ({
                                                                treeData: changeNodeAtPath({
                                                                    treeData: state.treeData,
                                                                    path,
                                                                    getNodeKey,
                                                                    newNode: {...node, titleText}
                                                                })
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                                :
                                                (node.typeID === null ?
                                                        <div className="tree-node">
                                                            <Image className="section-editor-image" src={node.hideImage === 0 ? (node.imageURL || node.serviceImageURL) : null} alt="Node image" width="50px" height="50px" onClick={() => this.showEditImageModal(path)} placeholder={placeholder} />
                                                            <div className="float-left">
                                                                <div style={{display: "inline-block"}}>
                                                                    <TreeNodeInput
                                                                        value={node.typeText || "Submenu"}
                                                                        className="type float-left"
                                                                        placeholder="No type title"
                                                                        onChange={typeText => {
                                                                            this.setState(state => ({
                                                                                treeData: changeNodeAtPath({
                                                                                    treeData: state.treeData,
                                                                                    path,
                                                                                    getNodeKey,
                                                                                    newNode: {...node, typeText}
                                                                                })
                                                                            }))
                                                                        }}
                                                                    />
                                                                    {node.data && SubmenuLayoutType.getTitle(node.data.carouselSize) !== null &&
                                                                    <span className="label submenu">{ SubmenuLayoutType.getTitle(node.data.carouselSize) }</span>
                                                                    }
                                                                    {node.data && node.data.showHeader === 1 &&
                                                                    <span className="label submenu">Show header</span>
                                                                    }
                                                                    {node.data && node.data.showHeader === 1 && node.data.showHeaderSubtitle === 1 &&
                                                                    <span className="label submenu">Show subtitle</span>
                                                                    }
                                                                    {node.data && node.data.showCellHeader === 1 &&
                                                                    <span className="label submenu">Item shows type title</span>
                                                                    }
                                                                    <span style={{float: "clear"}}></span>
                                                                </div>
                                                                <div style={{display: "block", float: "none"}}>
                                                                    <TreeNodeInput
                                                                        value={node.titleText}
                                                                        className="title"
                                                                        placeholder="No title"
                                                                        onChange={titleText => {
                                                                            if (!titleText.length) {
                                                                                return;
                                                                            }
                                                                            this.setState(state => ({
                                                                                treeData: changeNodeAtPath({
                                                                                    treeData: state.treeData,
                                                                                    path,
                                                                                    getNodeKey,
                                                                                    newNode: {...node, titleText}
                                                                                })
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <TreeNodeInput
                                                                        value={node.subtitleText}
                                                                        className="subtitle"
                                                                        placeholder="No subtitle"
                                                                        onChange={subtitleText => {
                                                                            this.setState(state => ({
                                                                                treeData: changeNodeAtPath({
                                                                                    treeData: state.treeData,
                                                                                    path,
                                                                                    getNodeKey,
                                                                                    newNode: {...node, subtitleText}
                                                                                })
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="tree-node">
                                                            <Image className="section-editor-image" src={node.hideImage === 0 ? (node.imageURL || node.serviceImageURL) : null} onClick={() => this.showEditImageModal(path)} alt="Node image" width="50px" height="50px" placeholder={placeholder}/>
                                                            <div className="float-left">
                                                                <div style={{display: "inline-block"}}>
                                                                    <TreeNodeInput
                                                                        value={node.typeText}
                                                                        className="type"
                                                                        placeholder="No type title"
                                                                        onChange={typeText => {
                                                                            this.setState(state => ({
                                                                                treeData: changeNodeAtPath({
                                                                                    treeData: state.treeData,
                                                                                    path,
                                                                                    getNodeKey,
                                                                                    newNode: {...node, typeText}
                                                                                })
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{display: "block", float: "none"}}>
                                                                    <TreeNodeInput
                                                                        value={node.titleText}
                                                                        className="title"
                                                                        placeholder="No title"
                                                                        onChange={titleText => {
                                                                            if (!titleText.length) {
                                                                                return;
                                                                            }
                                                                            this.setState(state => ({
                                                                                treeData: changeNodeAtPath({
                                                                                    treeData: state.treeData,
                                                                                    path,
                                                                                    getNodeKey,
                                                                                    newNode: {...node, titleText}
                                                                                })
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <TreeNodeInput
                                                                        value={node.subtitleText}
                                                                        className="subtitle"
                                                                        placeholder="No subtitle"
                                                                        onChange={subtitleText => {
                                                                            this.setState(state => ({
                                                                                treeData: changeNodeAtPath({
                                                                                    treeData: state.treeData,
                                                                                    path,
                                                                                    getNodeKey,
                                                                                    newNode: {...node, subtitleText}
                                                                                })
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                )
                                            ,
                                            buttons: this.state.item.langID !== null ? [] : (node.typeID !== null ?
                                                [
                                                    <Button
                                                        color={Colors.ALERT}
                                                        size={Sizes.TINY}
                                                        onClick={() => {
                                                            this.setState(state => ({
                                                                treeData: removeNodeAtPath({
                                                                    treeData: state.treeData,
                                                                    path,
                                                                    getNodeKey
                                                                })
                                                            }))
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon="trash"/>
                                                    </Button>
                                                ]
                                                :
                                                [
                                                    <Button
                                                        color={Colors.PRIMARY}
                                                        size={Sizes.TINY}
                                                        onClick={() => {
                                                            this.showAddSubmenu(path);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon="edit" />
                                                    </Button>,
                                                    <Button
                                                        color={Colors.ALERT}
                                                        size={Sizes.TINY}
                                                        onClick={() => {
                                                            this.setState(state => ({
                                                                treeData: removeNodeAtPath({
                                                                    treeData: state.treeData,
                                                                    path,
                                                                    getNodeKey
                                                                })
                                                            }))
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon="trash"/>
                                                    </Button>
                                                ]),
                                            className: node.isSectionHeader ? "section-header" : ""
                                        })}
                                    /> :
                                    this.state.item.langID === null ? (<p>No structure yet - start by adding submenus or services</p>) : (<p>No structure yet - please create and save section structure in the base language before adding translations</p>)
                                }
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        );

        return (
            <BlockUi tag="div" blocking={this.state.isUploading}>
                {this.state.item.langID === null ?
                <PageContent pathElements={pathElements} >
                    <div className="gird-x grid-padding-x">
                        <div className="cell small-12" style={{margin: "20px 0"}}>
                            <div className="grid-x grid-padding-x">
                                {!this.props.location.state.item && this.props.location.state.permissions.allOrgs &&
                                <div className="small-12 medium-6 large-3 cell ">
                                    <span>Organisation</span>
                                    <ReactSelect
                                        name="organisationID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.organisationID}
                                        options={this.state.organisationOptions}
                                    />
                                </div>
                                }
                                <div className={"small-12 medium-6 large-3 cell " + (this.state.errors.name && "error")}>
                                    <span>* Configuration name</span>
                                    <input name="name" type="text" value={this.state.item.name} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.name}</span>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Visibility</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <Button size={Sizes.SMALL} onClick={this.addVisibilityVariation} disabled={this.state.visibility.length >= this.state.campusOptions.length}>
                                                    Add&nbsp;
                                                    <FontAwesomeIcon icon="plus"/>
                                                </Button>
                                            </div>
                                        </div>
                                        {this.state.visibility.map(item =>
                                            <div className="grid-x grid-padding-x"  >
                                                <div className="cell small-12 medium-6 large-3">
                                                    <div className="input-button-group">
                                                        <button className="button clear alert push-left" onClick={() => this.removeVisibilityVariation(item)}>
                                                            <FontAwesomeIcon icon="times-circle"/>
                                                        </button>
                                                        <div className="input-element">
                                                            <span>Campus</span>
                                                            <ReactSelect
                                                                style={{width: "100%"}}
                                                                name="campuses"
                                                                onChange={(e) => this.handleVisibilityChange(e, item)}
                                                                value={item.campus}
                                                                options={this.state.campusOptions}
                                                                filterOption={(option) => !this.state.visibility.find(v => v.campus && v.campus.toString() === option.value.toString())}
                                                                placeholder="All"
                                                                className="react-select"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cell small-12 medium-6 large-9">
                                                    <span>User roles</span>
                                                    <ReactSelect
                                                        name="roles"
                                                        onChange={(e) => this.handleVisibilityChange(e, item)}
                                                        value={item.roles}
                                                        options={item.campus ? this.state.roleOptions[item.campus] : []}
                                                        isMulti
                                                        isDisabled={!item.campus}
                                                        className="react-select"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Translations</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <span>Add translation for</span>
                                                <ReactSelect
                                                    name="selectedLanguage"
                                                    className="react-select translation-selector"
                                                    onChange={this.handleChange}
                                                    value={this.state.selectedLanguage}
                                                    options={this.state.languageOptions}
                                                    onBlur={this.handleBlur}
                                                />
                                                <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <ReactTable
                                                    columns={[
                                                        {
                                                            Header: 'Language',
                                                            accessor: 'langName'
                                                        },
                                                        {
                                                            Header: 'Actions',
                                                            Cell: row => (
                                                                <ButtonGroup size={Sizes.TINY}>
                                                                    <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                    <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                </ButtonGroup>
                                                            ),
                                                            maxWidth: 100
                                                        }
                                                    ]}
                                                    data={this.state.item.translations}
                                                    className='-striped'
                                                    showPagination={false}
                                                    noDataText={"No translations"}
                                                    pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            {editorContent}
                        </div>
                    </div>
                    <div className="fix-save">
                        <ButtonGroup>
                            <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                            <Button className="edit-button" color={Colors.SUCCESS} disabled={!this.state.validated} onClick={this.handleSubmit} >Save</Button>
                        </ButtonGroup>
                    </div>
                </PageContent>
                    :
                    <React.Fragment>
                        <div className="modal-head">
                            <h3>Section ({this.state.item.langName} translation)</h3>
                        </div>
                        <div className="modal-body">
                            {editorContent}
                        </div>
                        <div className="modal-footer">
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <div className="btn-wrap">
                                        <button className="button secondary" type="button" onClick={this.handleTranslationSubmit}>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="SupportModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </BlockUi>
        );
    }
}