import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import Image from "../../common/Image";
import FileInput from "../../common/FileInput";
import AxiosHelper from "../../../utils/AxiosHelper";
import { OrganisationRole } from "constants/OrganisationRole"
import DateHelper from 'utils/DateHelper'
import {Button, Colors} from "react-foundation";
import {APIClient} from "constants/APIClient";
import ActionForm from "../../cta/ActionForm";
import AnonymiseUsers from "./AnonymiseUsers";
import Modal from "react-responsive-modal";

export default class OrganisationForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                active: 1,
                roleID: null
            }, props.item),
            roleOptions: props.item.id ? [{value: props.item.roleID, label: props.item.roleName}] : [],
            timeZoneOptions: props.item.id ? [{value: props.item.timeZoneID, label: props.item.timeZoneTitle}] : [],
            validated: false,
            errors: {},
            touched: {},
            logoPickerURL: null,
            logoPickerFile: null,
            fullLogoPickerURL: null,
            fullLogoPickerFile: null,
            appLogoPickerURL: null,
            appLogoPickerFile: null,
            isUploading: false,
            uploadProgress: 0,
            showDeleteAllUsers: props.item.id ? props.item.active === 0 && context.permissions.appUser.deleteAll : false,
            modal: {
                isOpen: false,
                item: {}
            },
        };
    }

    componentDidMount() {
        this.validate();
        this.getRoleOptions();
        this.getTimeZoneOptions();
    }


    getRoleOptions = () => {
        Axios
            .get(API.organisation.getRoleTypes)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        roleOptions: res.data.data.roles.map((i) => { return {value: i.id, label: i.name}})
                    })
                }
            })
    }

    getTimeZoneOptions = () => {
        Axios
            .get(API.organisation.getTimeZones)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        timeZoneOptions: res.data.data.timeZones.map((i) => { return {value: i.id, label: i.title}})
                    })
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {

                let formData = new FormData();

                formData.append("active", this.state.item.active ? 1 : 0);
                formData.append("name", this.state.item.name);
                formData.append("iosBundleID", this.state.item.iosBundleID);
                formData.append("timeZoneID", this.state.item.timeZoneID);
                formData.append("appName", this.state.item.appName);

                if (this.state.item.androidBundleName) {
                    formData.append("androidBundleName", this.state.item.androidBundleName);
                }

                if (this.state.item.iosAppLink) {
                    formData.append("iosAppLink", this.state.item.iosAppLink);
                }

                if (this.state.item.androidAppLink) {
                    formData.append("androidAppLink", this.state.item.androidAppLink);
                }

                if (!this.state.item.id) {
                    formData.append("roleID", this.state.item.roleID);
                }
                else {
                    formData.append("id", this.state.item.id);
                }

                if (this.state.logoPickerFile) {
                    formData.append("logoFile", this.state.logoPickerFile);
                }

                if (this.state.fullLogoPickerFile) {
                    formData.append("fullLogoFile", this.state.fullLogoPickerFile);
                }

                if (this.state.appLogoPickerFile) {
                    formData.append("appLogoFile", this.state.appLogoPickerFile);
                }

                Axios
                    .post((this.state.item.id ? API.organisation.updateOrganisation : API.organisation.createOrganisation), formData, {
                        headers: {
                            'Content-Type': formData.has('logoFile') || formData.has('fullLogoFile') || formData.has('appLogoFile') ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                        },
                        onUploadProgress: (progressEvent) => {
                            this.setState({
                                uploadProgress: AxiosHelper.getUploadProgress(progressEvent)
                            });
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleFileDelete = (name) => {
        if (name === "logoFile") {
            this.setState({
                logoPickerURL: null,
                logoPickerFile: null
            });
        }
        else if (name === "fullLogoFile") {
            this.setState({
                fullLogoPickerFile: null,
                fullLogoPickerURL: null
            })
        }
        else if (name === "appLogoFile") {
            this.setState({
                appLogoPickerFile: null,
                appLogoPickerURL: null
            })
        }
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let name = event.target.name;

        let self = this;

        reader.onloadend = () => {

            let newState = {};

            if (name === "logoFile") {
                newState = {
                    logoPickerFile: file ? file : null,
                    logoPickerURL: file ? reader.result : null
                }
            }
            else if (name === "fullLogoFile") {
                newState = {
                    fullLogoPickerFile: file ? file : null,
                    fullLogoPickerURL: file ? reader.result : null
                }
            }
            else if (name === "appLogoFile") {
                newState = {
                    appLogoPickerFile: file ? file : null,
                    appLogoPickerURL: file ? reader.result : null
                }
            }

            self.setState(newState, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.name) {
            if (touched.name) {
                errors.name = "Organisation name is required";
            }
            validated = false;
        }

        if (!item.roleID) {
            if (touched.roleID) {
                errors.roleID = "Role type is required"
            }
            validated = false;
        }

        if (!item.timeZoneID) {
            if (touched.timeZoneID) {
                errors.timeZoneID = "Time Zone is required"
            }
            validated = false;
        }

        if (!item.appName) {
            if (touched.appName) {
                errors.appName = "App's name is required"
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleAnonymiseUsers = () => {
        if (this.state.modal.isOpen) { return; }
        if (!this.state.item.id) { return; }
        this.showAnonymiseUsersModal();
    }

    showAnonymiseUsersModal = () => {
        const content = (
            <AnonymiseUsers
                organisationID={this.state.item.id}
                organisationName={this.state.item.name}
                onSubmitted={() => this.closeModal()}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: Object.assign({}, this.state.modal, {
                isOpen: true,
                content: content
            })
        })
    }

    closeModal = () => {
        this.setState({
            modal: {
                isOpen: false,
                content: null
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} showProgress={this.state.logoPickerFile !== null || this.state.fullLogoPickerFile !== null || this.state.appLogoPickerFile !== null} progress={this.state.uploadProgress} />

                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit Organisation" : "New Organisation"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="active" className="text-right middle">Active</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.active && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active === 1} onBlur={this.handleBlur} />
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.active}</small>
                                </label>
                            </div>
                        </div>
                        {this.state.showDeleteAllUsers &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="anonymiseUsers" className="text-right middle">Mobile Users</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <button className="button alert" name="anonymiseUsers" type="button" onClick={this.handleAnonymiseUsers}>Delete all accounts</button>
                                </div>
                            </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="name" className="text-right middle ">* Organisation Name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.name && "error")}>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.item.name} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.name}</small>
                            </div>
                        </div>
                        {this.props.item.id ?
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="roleName" className="text-right middle ">* Role</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="roleName" value={this.state.item.roleName} disabled/>
                                </div>
                            </div>
                            :
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="roleID" className="text-right middle">* Role</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.roleID && "error")}>
                                    <ReactSelect
                                        name="roleID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.roleID}
                                        options={this.state.roleOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.roleID}</small>
                                </div>
                            </div>
                        }
                        {this.state.item.roleID && (this.state.item.roleID === OrganisationRole.ADMIN || this.state.item.roleID === OrganisationRole.APP_USER) &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="iosBundleID" className="text-right middle ">App Bundle ID</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.iosBundleID && "error")}>
                                    <input type="text" name="iosBundleID" onChange={this.handleChange} value={this.state.item.iosBundleID} onBlur={this.handleBlur} disabled={this.context.permissions.organisations.modifyBundleID !== true}/>
                                    <small className="error">{this.state.errors.iosBundleID}</small>
                                    <small className="field-info">App Bundle ID must match the bundle ID set in the mobile apps for the organisation. Please do not change that value unless you are sure what you are doing.</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="androidBundleName" className="text-right middle ">Android Bundle Name</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.androidBundleName && "error")}>
                                    <input type="text" name="androidBundleName" onChange={this.handleChange} value={this.state.item.androidBundleName} onBlur={this.handleBlur} />
                                    <small className="field-info">This is required to handle deep links from Android notifications</small>
                                    <small className="error">{this.state.errors.androidBundleName}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="iosAppLink" className="text-right middle ">iOS App Link</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.iosAppLink && "error")}>
                                    <input type="text" name="iosAppLink" onChange={this.handleChange} value={this.state.item.iosAppLink} onBlur={this.handleBlur} disabled={this.context.permissions.organisations.modifyBundleID !== true}/>
                                    <small className="error">{this.state.errors.iosAppLink}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="androidAppLink" className="text-right middle ">Android App Link</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.androidAppLink && "error")}>
                                    <input type="text" name="androidAppLink" onChange={this.handleChange} value={this.state.item.androidAppLink} onBlur={this.handleBlur} disabled={this.context.permissions.organisations.modifyBundleID !== true}/>
                                    <small className="error">{this.state.errors.androidAppLink}</small>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="timeZoneID" className="text-right middle">* Time Zone</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.timeZoneID && "error")}>
                                <ReactSelect
                                    name="timeZoneID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.timeZoneID}
                                    options={this.state.timeZoneOptions}
                                    onBlur={this.handleBlur}
                                />
                                <small className="error">{this.state.errors.timeZoneID}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="logoFile" className="text-right middle">Select Emblem Logo</label>
                            </div>
                            <div className="large-9 small-9 cell ">
                                <FileInput
                                    name="logoFile"
                                    onChange={this.handleFilePickerChange}
                                    onBlur={this.handleBlur}
                                    onDelete={this.handleFileDelete}
                                    placeholder="Not selected. Ideal size W:126px H:126px (jpg or png)"
                                    accept="image/jpeg,image/jpg,image/png"
                                />
                                <small className="field-info">This size logo will be used next to the post's title in the feed</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="logoPreviewFile" className="text-right middle">Emblem Logo</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.logoFile && "error")} style={{marginBottom: 20}}>
                                {this.state.logoPickerURL &&
                                <Image src={this.state.logoPickerURL} width={42} height={42} border={true} fit="contain" />
                                }
                                {!this.state.logoPickerURL && this.state.item.logoImageURL &&
                                <React.Fragment>
                                    <Image src={this.state.item.logoImageURL} width={42} height={42} border={true} fit="contain" />
                                </React.Fragment>
                                }
                                {!this.state.logoPickerURL && !this.state.item.logoImageURL &&
                                <p>No image selected</p>
                                }
                                <small className="error">{this.state.errors.logoFile}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Marketing and Transactional Emails</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="appName" className="text-right middle ">App's Name</label>
                                        </div>
                                        <div className={"small-9 cell " + (this.state.errors.appName && "error")}>
                                            <input type="text" name="appName" onChange={this.handleChange} value={this.state.item.appName} onBlur={this.handleBlur} />
                                            <small className="error">{this.state.errors.appName}</small>
                                            <small className="field-info">App's name is used when communicating with users and ideally it should match app's name in the App Store.</small>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="appLogoFile" className="text-right middle">Select App's Logo</label>
                                        </div>
                                        <div className="large-9 small-9 cell ">
                                            <FileInput
                                                name="appLogoFile"
                                                onChange={this.handleFilePickerChange}
                                                onBlur={this.handleBlur}
                                                onDelete={this.handleFileDelete}
                                                placeholder="Not selected. Ideal size W:500px H:500px (jpg or png)"
                                                accept="image/jpeg,image/jpg,image/png"
                                            />
                                            <small className="field-info">App's logo is used when communicating with users and ideally should match app's icon in the App Store. If App's logo is not uploaded then the Emblem Logo will be used instead, however if both logos are not uploaded then system will use the default UniWellBeing logo.</small>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="appLogoPreviewFile" className="text-right middle">App's Logo</label>
                                        </div>
                                        <div className={"large-9 small-9 cell " + (this.state.errors.appLogoFile && "error")} style={{marginBottom: 20}}>
                                            {this.state.appLogoPickerURL &&
                                            <Image src={this.state.appLogoPickerURL} width={240} height={80} border={true} fit="contain" />
                                            }
                                            {!this.state.appLogoPickerURL && this.state.item.appLogoImageURL &&
                                            <React.Fragment>
                                                <Image src={this.state.item.appLogoImageURL} width={240} height={80} border={true} fit="contain" />
                                            </React.Fragment>
                                            }
                                            {!this.state.appLogoPickerURL && !this.state.item.appLogoImageURL &&
                                            <p>No image selected</p>
                                            }
                                            <small className="error">{this.state.errors.appLogoFile}</small>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="ModalAnonymiseUsers"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
