// Mapped to the DB

import nativeFeatureIcon from "../assets/img/dynamic_native_feature.png";
import websiteIcon from "../assets/img/dynamic_website.png";
import podcastIcon from "../assets/img/dynamic_podcast.png";
import pageIcon from "../assets/img/dynamic_page.png";
import challengeIcon from "../assets/img/dynamic_challenge_service.png";
import videoIcon from "../assets/img/dynamic_video.png";
import habitIcon from "../assets/img/dynamic_habits.png";
import submenuIcon from "../assets/img/dynamic_submenu.png";

import messageIcon from "../assets/img/chatflow/message.png";
import answerIcon from "../assets/img/chatflow/answer.png";
import imageIcon from "../assets/img/chatflow/media.png";
import conditionIcon from "../assets/img/chatflow/condition.png";
import actionIcon from "../assets/img/chatflow/action.png";
import chatFlowIcon from "../assets/img/chatflow/linked_chat_flow.png";
import textFieldIcon from "../assets/img/chatflow/user_input.png";
import serviceIcon from "../assets/img/chatflow/service_link.png";
import redirectIcon from "../assets/img/chatflow/redirect.png";

export const ChatItemType = {
    MESSAGE: 1,
    ANSWER: 2,
    IMAGE: 3,
    CONDITION: 4,
    ACTION: 5,
    CHATFLOW: 6,
    TEXTFIELD: 7,
    SERVICE: 8,
    REDIRECT: 9,
    REVIEW: 10,
    ANIMATED_SVG: 11,

    getBackgroundColor: (chatItemType) => {
        switch (chatItemType) {
            case ChatItemType.MESSAGE:
                return "#5eb9ff";
            case ChatItemType.ANSWER:
                return "#5eff6e";
            case ChatItemType.IMAGE:
                return "#b95eff";
            case ChatItemType.CONDITION:
                return "#ff5e5e";
            case ChatItemType.TEXTFIELD:
                return "#666666";
            case ChatItemType.ACTION:
                return "#faff5e";
            case ChatItemType.SERVICE:
                return "#ffa95e";
            case ChatItemType.CHATFLOW:
                return "#ff5ee4";
            case ChatItemType.REDIRECT:
                return "#5471ff";
            case ChatItemType.REVIEW:
                return "#7d4aff";
            case ChatItemType.ANIMATED_SVG:
                return "#ff804a";
            default:
                return "#BDBDBD";
        }
    },

    getIcon: (chatItemType) => {
        switch (chatItemType) {
            case ChatItemType.MESSAGE:
                return messageIcon;
            case ChatItemType.ANSWER:
                return answerIcon;
            case ChatItemType.IMAGE:
                return imageIcon;
            case ChatItemType.CONDITION:
                return conditionIcon;
            case ChatItemType.TEXTFIELD:
                return textFieldIcon;
            case ChatItemType.ACTION:
                return actionIcon;
            case ChatItemType.SERVICE:
                return serviceIcon;
            case ChatItemType.CHATFLOW:
                return chatFlowIcon;
            case ChatItemType.REDIRECT:
                return redirectIcon;
            case ChatItemType.REVIEW:
                return redirectIcon;
            case ChatItemType.ANIMATED_SVG:
                return redirectIcon;
            default:
                return null;
        }
    },

    getNodeClass: (chatItemType) => {
        switch (chatItemType) {
            case ChatItemType.MESSAGE:
                return "node-chat-message";
            case ChatItemType.ANSWER:
                return "node-chat-answer";
            case ChatItemType.IMAGE:
                return "node-chat-image";
            case ChatItemType.CONDITION:
                return "node-chat-condition";
            case ChatItemType.TEXTFIELD:
                return "node-chat-textfield";
            case ChatItemType.ACTION:
                return "node-chat-action";
            case ChatItemType.SERVICE:
                return "node-chat-service";
            case ChatItemType.CHATFLOW:
                return "node-chat-chatflow";
            case ChatItemType.REDIRECT:
                return "node-chat-redirect";
            case ChatItemType.REVIEW:
                return "node-chat-review";
            case ChatItemType.ANIMATED_SVG:
                return "node-chat-animation";
            default:
                return null;
        }
    }
};

Object.freeze(ChatItemType);