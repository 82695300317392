import React from 'react'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation"
import Axios from "axios";
import {API} from "../../api/APIClient"
import BlockUI from 'react-block-ui'
import ReactTable from "react-table";
import Modal from "react-responsive-modal";
import ChatImportRelationships from "components/chatflows/ChatImportRelationships"
import { NotificationManager } from "react-notifications"
import StandardModal from "../common/StandardModal";

export default class ChatImport extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            chatFlows: [],
            importCompleted: false,
            isImporting: false,
            isSubmitting: false,
            importID: null,
            modal: {
                isOpen: false,
                content: null,
                size: "modal-medium"
            }
        };
    }

    runImport = () => {

        if (!this.state.isImporting) {

            this.setState({
                isImporting: true,
                importCompleted: false,
                chatFlows: []
            }, () => {


                Axios
                    .post(API.chatImport.initiateImport)
                    .then((res) => {
                        if (res.data.success) {

                            let chatFlows = res.data.data.chatFlows;

                            this.setState({
                                isImporting: false,
                                chatFlows: chatFlows,
                                importID: res.data.data.importID,
                                importCompleted: true
                            })
                        }
                        else {
                            this.setState({
                                isImporting: false
                            }, () => {
                                NotificationManager.error((res.data.error && res.data.error.length > 0) ? res.data.error : "Unknown Error")
                            })
                        }
                    })

            })

        }
    }


    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;


    }


    showSubmitWarning = () => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Remaining errors"
                    content={(
                        <React.Fragment>
                            <p>You are trying to import chat flows with mapping errors. These errors would cause chat flows to not work properly and may be difficult to fix later. Are you sure to continue?</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.closeModal(this.performImport)}>
                                Import with errors
                            </button>
                        </React.Fragment>
                    )}
                    error={null}
                    isUploading={false}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    performImport = () => {
        if (! this.state.isSubmitting) {
            this.setState({
                isSubmitting: true
            }, () => {

                let changes = {};


                // We don't allow any changes at the moment. There are plenty of bugs around this functionality and
                // we need to finish the import asap so the AU server can be deployed with the new version.

                // this.state.chatFlows.forEach((chatFlow) => {
                //     chatFlow.relationships.forEach((e) => {
                //         e.rel.forEach((rel) => {
                //             if (rel.overwritten === true) {
                //                 if (!changes.hasOwnProperty(e.uuid)) {
                //                     changes[e.uuid] = {};
                //                 }
                //                 changes[e.uuid][rel.key] = rel;
                //             }
                //         })
                //     })
                // });


                Axios
                    .post((API.chatImport.performImport), {
                        changes: changes,
                        importID: this.state.importID
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isSubmitting: false,
                                chatFlows: [],
                                importID: null,
                                importCompleted: false
                            }, () => {
                                NotificationManager.success("Chat flows imported successfully");
                            })
                        }
                        else {
                            this.setState({
                                isSubmitting: false
                            }, () => {
                                NotificationManager.error(((res.data || {}).error || {}).desc || "Unknown Error");
                            })
                        }
                    })
            })
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();
        let haveErrors = false;
        this.state.chatFlows.forEach((o) => {
            if (o.errorsCount > 0) {
                haveErrors = true;
            }
        })

        if (haveErrors) {
            this.showSubmitWarning();
        }
        else {
            this.performImport();
        }
    }


    getTRPropsType(state, rowInfo, column) {
        
        if (!rowInfo) { return {}; }
        
        const { original } = rowInfo;
        const { errorsCount } = original;

        if (errorsCount > 0) {
            let fixedCount = 0;
            original.relationships.forEach((m) => {
                m.rel.forEach((r) => {
                    if (r.overwritten === true) {
                        fixedCount++;
                    }
                })
            });
            
            return {
                style: {
                    background: errorsCount !== fixedCount ? "#f59790" : "#92d689"
                }
            };
        }

        return {};
    }


    showItemModal = (row) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <ChatImportRelationships
                    chatFlow={this.state.chatFlows[row.index]}
                    onSubmitted={(chatFlow) => {
                        let chatFlows = this.state.chatFlows.slice();
                        chatFlows[row.index] = chatFlow;
                        this.setState({
                            chatFlows: chatFlows
                        }, () => {
                            this.closeModal()
                        })
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: {
                    isOpen: true,
                    content: content,
                    size: "modal-large"
                }
            })
        }
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }



    render() {

        let chatFlowsTableColumns = [
            {
                Header: "ID",
                accessor: "sourceChatFlowID"
            },
            {
                Header: "Title",
                accessor: "title"
            },
            {
                Header: "Action",
                accessor: "sourceChatFlowID",
                Cell: row => {
                    if (row.original.destChatFlowID) {
                        return "Update";
                    }
                    else {
                        return "Create";
                    }
                }
            },
            {
                Header: "Mappings",
                accessor: "successCount"
            },
            {
                Header: "Errors",
                accessor: "errorsCount"
            },
            {
                Header: "Fixed",
                accessor: "errorsCount",
                Cell: row => {
                    let fixed = 0;
                    row.original.relationships.forEach((o) => {
                        o.rel.forEach((r) => {
                            if (r.overwritten === true) { fixed++; }
                        })
                    });
                    return fixed;
                }
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        <Button color={Colors.PRIMARY} onClick={() => this.showItemModal(row)}>Details</Button>
                    </ButtonGroup>
                ),
                maxWidth: 100,
            }
        ];

        return (
            <BlockUI tag="div" blocking={this.isSubmitting}>
                <div className="gird-x grid-padding-x">
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Initiate New Import</legend>
                                    <BlockUI tag="div" blocking={this.state.isImporting}>
                                        {this.state.isImporting ?
                                            <React.Fragment>
                                                <p style={{textAlign: "center"}}><strong>Please wait and do not refresh the page. The initial import may take few minutes to complete.</strong></p>
                                            </React.Fragment>
                                         :
                                            <React.Fragment>
                                                <p>When importer is initiated it will check the US-QA server for any chat flows that do not exist in this environment or for chat flows
                                                    that do exist but have been updated since last import</p>
                                                <p>If any chat flow is highlighted in red, it would mean that it was not possible to automatically find matching local records for one or more
                                                    relationships that are set in the chat flow. In order to fix these errors, missing records would need to be recreated in the local environment and import should be initiated again.
                                                    It is discouraged to import chat flows with existing errors as it may not be possible to fix them after import is completed.</p>
                                                <p>The following relationship entities will be copied automatically if not found in the local environment:
                                                    <ul>
                                                        <li>Chat notifications</li>
                                                        <li>Custom properties</li>
                                                        <li>Images</li>
                                                    </ul>
                                                </p>
                                                <p>The following relationship entities must be recreated manually if not found in the local environment:
                                                    <ul>
                                                        <li>Habits</li>
                                                        <li>Podcasts</li>
                                                        <li>Challenges</li>
                                                        <li>Emotions</li>
                                                        <li>Services</li>
                                                        <li>Wellbeing Categories</li>
                                                    </ul>
                                                </p>
                                            </React.Fragment>

                                        }
                                        <Button onClick={this.runImport}>Initiate new import</Button>
                                    </BlockUI>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.importCompleted &&
                <div className="gird-x grid-padding-x" style={{marginBottom: 100}}>
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Import result</legend>
                                    <ReactTable
                                        columns={chatFlowsTableColumns}
                                        data={this.state.chatFlows}
                                        minRows={1}
                                        showPagination={false}
                                        manual
                                        noDataText={"No chat flows to import"}
                                        className='-striped'
                                        getTrProps={this.getTRPropsType}
                                    />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="fix-save">
                    <ButtonGroup>
                        <Button className="edit-button" color={Colors.SUCCESS} onClick={this.handleSubmit} disabled={!(this.state.chatFlows.length > 0)}>Import</Button>
                    </ButtonGroup>
                </div>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatRelModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </BlockUI>
        )
    }

}


