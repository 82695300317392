import React from 'react'
import Axios from 'axios'
import { API } from 'api/APIClient'
import Image from 'components/common/Image'
import FileInput from "../common/FileInput";
import Placeholder from "assets/img/placeholder.png"
import UUID from "../../utils/UUID";
import {ChatItemType} from "../../constants/ChatItemType";

class ChatImageForm extends React.Component {

    static defaultProps = {
        item: {},
        onSubmitted: () => void 0
    }

    constructor(props) {
        super(props);

        console.log(props);

        this.state = {
            item: Object.assign({
                imageURL: null,
                imageID: null,
                altText: null,
                typeText: "Media",
                useCustomDisplayTime: null,
                displayTime: 0,
                useCustomTypingTime: null,
                typingTime: 0,
                typeID: ChatItemType.IMAGE,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            errors: {},
            touched: {},
            imagePickerURL: null,
            imagePickerFile: null,
            isUploading: false
        };
    }

    componentDidMount() {
        this.validate();
    }

    handleFileDelete = (name) => {
        this.setState({
            imagePickerFile: null,
            imagePickerURL: null
        }, () => {
            this.validate()
        })
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = Object.assign({}, this.state.item);
        item[name] = value;

        if (name === "useCustomDisplayTime") {
            item.displayTime = 0;
        }

        if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        }

        this.setState({
            item: item
        }, () => {
            this.validate();
        })
    }


    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            });

            let formData = new FormData();

            formData.append("typeID", 23); // Chat flow image

            if (this.state.imagePickerFile) {
                formData.append("file", this.state.imagePickerFile);
            }

            Axios
                .post(API.image.uploadImage, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        let newItem = Object.assign({}, this.state.item);
                        newItem.imageID = res.data.data.imageID;
                        newItem.imageURL = res.data.data.imageURL;
                        newItem.nodeText = "Image";
                        newItem.typeText = "Media";
                        this.props.onSubmitted(newItem);
                    }
                    else if (res.data.error) {
                        let errors = this.state.errors;
                        errors.genericError = res.data.error.desc;
                        this.setState({
                            errors: errors
                        });
                    }
                    this.setState({
                        isUploading: false
                    })
                })
        }
    }


    validate = () => {

        let validated = this.state.imagePickerURL !== null;

        this.setState({
            validated: validated
        })

    }

    render() {

        return (
            <React.Fragment>
                <div className="modblock">
                    <h2>Saving</h2>
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <div className="modal-head">
                    <h3>Edit Image</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="imageFile" className="text-right middle">Change Image</label>
                            </div>
                            <div className="large-9 small-9 cell ">
                                <FileInput
                                    name="file"
                                    onChange={this.handleFilePickerChange}
                                    onBlur={this.handleBlur}
                                    onDelete={this.handleFileDelete}
                                    placeholder="Not selected."
                                    accept="image/jpeg,image/jpg,image/png"
                                />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="imageFile" className="text-right middle">Current Image</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.imageFile ? "error" : "")} style={{marginBottom: 20}}>
                                {this.state.imagePickerURL &&
                                <React.Fragment>
                                    <Image src={this.state.imagePickerURL} width={200} height={200} border={true} style={{marginRight: 10}}/>
                                    <Image src={this.state.imagePickerURL} width={200} height={100} border={true} />
                                </React.Fragment>
                                }
                                {!this.state.imagePickerURL && this.state.item.imageURL &&
                                <React.Fragment>
                                    <Image src={this.state.item.imageURL} width={200} height={200} border={true} placeholder={Placeholder} style={{marginRight: 10}}/>
                                    <Image src={this.state.item.imageURL} width={200} height={100} border={true} placeholder={Placeholder}/>
                                </React.Fragment>
                                }
                                {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                    <p>No image selected</p>
                                }
                                <small className="error">{this.state.errors.imageFile}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="altText" className="text-right middle">Alt text</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <textarea rows={5} name="altText" value={this.state.item.altText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="field-info">Image's description used by Voice Over reader in the app</small>
                                <small className="error">{this.state.errors["altText"]}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomDisplayTime" className="text-right middle">Custom display time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomDisplayTime" onChange={this.handleChange}
                                           checked={this.state.item.useCustomDisplayTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomDisplayTime === 1 &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="displayTime" className="text-right middle">* Display Time</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.displayTime && "error")}>
                                    <input name="displayTime" type="number" value={this.state.item.displayTime} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.displayTime}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime" onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                    <input name="typingTime" type="number" value={this.state.item.typingTime} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.typingTime}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">

                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </React.Fragment>
        );
    }
}

export default ChatImageForm;
