import React from 'react'
import Axios from 'axios'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import DateHelper from 'utils/DateHelper'
import ChatVariablesSelector from "./ChatVariablesSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-responsive-modal";

export default class ChatCustomVariableForm extends AppComponent {


    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                key: null,
                desc: null,
                defaultValueKey: null,
            }, props.item),
            validated: false,
            errors: {},
            touched: {},
            isUploading: false,
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.validate();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {

                let formData = new FormData();


                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }
                else {
                    formData.append("key", this.state.item.key);
                }

                formData.append("desc", this.state.item.desc);

                if (this.state.item.defaultValueKey) {
                    formData.append("defaultValueKey", this.state.item.defaultValueKey);
                }

                Axios
                    .post((this.state.item.id ? API.chat.updateCustomProperty : API.chat.createCustomProperty), formData)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);
        item[name] = value;


        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={(variable) => {
                    let item = Object.assign({}, this.state.item);
                    item.defaultValueKey = variable.key;
                    this.setState({item: item}, () => {
                        this.validate();
                        this.closeModal();
                    })
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    handleDefaultValueDelete = () => {

        let item = Object.assign({}, this.state.item);
        item.defaultValueKey = null;
        this.setState({item: item}, () => {
            this.validate();
        })

    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }




    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.key) {
            if (touched.key) {
                errors.key = "Key is required";
            }
            validated = false;
        }
        else {
            let keyValidated = this.validateKey(item.key);
            if (!keyValidated) {
                validated = false;
                errors.key = "Key can only use alpha numerical characters and dot"
            }
        }

        if (!item.desc) {
            if (touched.desc) {
                errors.desc = "Description is required";
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    validateKey = (key) => {
        const expr = /^[a-zA-Z0-9.]*$/;
        return expr.test(key);
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit Custom Property" : "New Custom Property"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="key" className="text-right middle ">* Key</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.key && "error")}>
                                <input type="text" name="key" onChange={this.handleChange} value={this.state.item.key} onBlur={this.handleBlur} disabled={!!this.state.item.id}/>
                                <small className="error">{this.state.errors.key}</small>
                                <small className="field-info">Please only use alpha numerical characters and dot. No spaces allowed. Camel case should be applied for multiple words. Logical groups can be created with a dot symbol.
                                eg. Let's say you wanted to find out what is users favourite genre of books and how many books they read per month, you could create the following two keys: user.reading.favouriteBookGenre and user.reading.booksPerMonth .
                                It is not mandatory to follow these conventions but if followed it can make the functionality easier to manage</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="desc" className="text-right middle ">* Description</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.desc && "error")}>
                                <input type="text" name="desc" onChange={this.handleChange} value={this.state.item.desc} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.desc}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="condition" className="text-right middle">Default Value</label>
                            </div>
                            <div className="small-9 cell ">
                                <div>
                                    <div className="input-group">
                                        <input className="input-group-field" type="text" value={this.state.item.defaultValueKey || "No default value" }  disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button alert" onClick={this.handleDefaultValueDelete} disabled={!this.state.item.defaultValueKey}><FontAwesomeIcon icon="times-circle"/></button>
                                            <button type="button" className="button" onClick={this.showVariablesSelector}>Select System Property</button>
                                        </div>
                                    </div>
                                    <small className="field-info">You can select a default value from system properties that would be used in case if user never set their custom property yet. Default value is used in the text as well as in the conditions.</small>
                                </div>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="CustomVariableInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
