// Mapped to the DB

export const ChatActionType = {
    ADD_HABIT: 1,
    JOIN_CHALLENGE: 2,
    START_PLAN: 3,
    ADD_COMPLETED_HABIT: 4,
    SCHEDULE_HABIT: 5,
    ADD_QUICK_CHAT_ACCESS: 6,
    UPDATE_CUSTOM_PROPERTY: 7,
    MOOD_RECORD: 8,
    JOURNAL_RECORD: 9,
    TINY_HABIT: 10,
    HABIT_MID_TARGET: 11,
    HABIT_GOAL: 12,
    ADD_NOTIFICATION: 13,
    REMOVE_NOTIFICATION: 14,


    getOptions: () => {
        return [
            {value: ChatActionType.ADD_HABIT, label: ChatActionType.getName(ChatActionType.ADD_HABIT)},
            {value: ChatActionType.ADD_COMPLETED_HABIT, label: ChatActionType.getName(ChatActionType.ADD_COMPLETED_HABIT)},
            {value: ChatActionType.SCHEDULE_HABIT, label: ChatActionType.getName(ChatActionType.SCHEDULE_HABIT)},
            {value: ChatActionType.JOIN_CHALLENGE, label: ChatActionType.getName(ChatActionType.JOIN_CHALLENGE)},
            {value: ChatActionType.START_PLAN, label: ChatActionType.getName(ChatActionType.START_PLAN)},
            {value: ChatActionType.ADD_QUICK_CHAT_ACCESS, label: ChatActionType.getName(ChatActionType.ADD_QUICK_CHAT_ACCESS)},
            {value: ChatActionType.UPDATE_CUSTOM_PROPERTY, label: ChatActionType.getName(ChatActionType.UPDATE_CUSTOM_PROPERTY)},
            {value: ChatActionType.MOOD_RECORD, label: ChatActionType.getName(ChatActionType.MOOD_RECORD)},
            {value: ChatActionType.JOURNAL_RECORD, label: ChatActionType.getName(ChatActionType.JOURNAL_RECORD)},
            {value: ChatActionType.TINY_HABIT, label: ChatActionType.getName(ChatActionType.TINY_HABIT)},
            {value: ChatActionType.HABIT_MID_TARGET, label: ChatActionType.getName(ChatActionType.HABIT_MID_TARGET)},
            {value: ChatActionType.HABIT_GOAL, label: ChatActionType.getName(ChatActionType.HABIT_GOAL)},
            {value: ChatActionType.ADD_NOTIFICATION, label: ChatActionType.getName(ChatActionType.ADD_NOTIFICATION)},
            {value: ChatActionType.REMOVE_NOTIFICATION, label: ChatActionType.getName(ChatActionType.REMOVE_NOTIFICATION)},
        ]
    },
    getName: (type) => {
        switch (type) {
            case ChatActionType.ADD_HABIT:
                return "ADD UNCOMPLETED HABIT";
            case ChatActionType.JOIN_CHALLENGE:
                return "JOIN CHALLENGE";
            case ChatActionType.START_PLAN:
                return "START HABITS PLAN";
            case ChatActionType.ADD_COMPLETED_HABIT:
                return "ADD COMPLETED HABIT";
            case ChatActionType.SCHEDULE_HABIT:
                return "SCHEDULE HABIT";
            case ChatActionType.ADD_QUICK_CHAT_ACCESS:
                return "ADD QUICK CHAT ACCESS";
            case ChatActionType.UPDATE_CUSTOM_PROPERTY:
                return "UPDATE CUSTOM PROPERTY";
            case ChatActionType.MOOD_RECORD:
                return "ADD MOOD RECORD";
            case ChatActionType.JOURNAL_RECORD:
                return "ADD JOURNAL RECORD";
            case ChatActionType.TINY_HABIT:
                return "UPDATE HABIT TO TINY HABIT";
            case ChatActionType.HABIT_MID_TARGET:
                return "UPDATE HABIT MID TARGET";
            case ChatActionType.HABIT_GOAL:
                return "UPDATE HABIT GOAL";
            case ChatActionType.ADD_NOTIFICATION:
                return "ADD NOTIFICATION";
            case ChatActionType.REMOVE_NOTIFICATION:
                return "REMOVE NOTIFICATION";
        }
    },
    getDescription: (type) => {
        switch (type) {
            case ChatActionType.ADD_HABIT:
                return "Adds a single uncompleted habit for the current day. Habit is added as 'anytime' habit. Habit is only added if there is no same uncompleted habit already scheduled";
            case ChatActionType.JOIN_CHALLENGE:
                return "Adds user to a challenge. This action will be ignored if the challenge is already closed.";
            case ChatActionType.START_PLAN:
                return "TODO - this is not implemented yet";
            case ChatActionType.ADD_COMPLETED_HABIT:
                return "Adds a single completed habit for the current day. Habit is added as 'anytime' habit. There is no limit on how many times habit can be added, so if user goes through the chat flow again, habit will be added second time";
            case ChatActionType.SCHEDULE_HABIT:
                return "Schedules habit for every day at the selected day time. If habit is already scheduled this action will be ignored.";
            case ChatActionType.ADD_QUICK_CHAT_ACCESS:
                return "Adds selected chat flow to quick accessible chats.";
            case ChatActionType.UPDATE_CUSTOM_PROPERTY:
                return "Updates custom property with the specified value";
            case ChatActionType.MOOD_RECORD:
                return "Adds moods record to user's journal";
            case ChatActionType.JOURNAL_RECORD:
                return "Adds note to user's journal";
            case ChatActionType.TINY_HABIT:
                return "Updates scheduled habit to Tiny Habit. Habit must be scheduled before action can be triggered. If habit is not scheduled action will be ignored";
            case ChatActionType.HABIT_MID_TARGET:
                return "Updates Mid Target for scheduled habit. Habit must be scheduled before action can be triggered. If habit is not scheduled action will be ignored";
            case ChatActionType.HABIT_GOAL:
                return "Updates Habit Goal for scheduled habit. Habit must be scheduled before action can be triggered. If habit is not scheduled action will be ignored";
            case ChatActionType.ADD_NOTIFICATION:
                return "Schedules Push notification for a user. If user has already same notification scheduled, the previous notification's settings will be overwritten by this configuration.";
            case ChatActionType.REMOVE_NOTIFICATION:
                return "Removes Push notification from user's schedule";
        }
    }
};

Object.freeze(ChatActionType);