// Mapped to the DB

export const SectionType = {
    SUPPORT: 1,
    MONEY: 2,
    I_NEED_HELP_NOW: 3,
    getPath: (type) => {
        switch (type) {
            case SectionType.SUPPORT:
                return "/support/";
            case SectionType.MONEY:
                return "/money/structure/";
            case SectionType.I_NEED_HELP_NOW:
                return "/needHelpNow/";
            default:
                return "";
        }
    }
};

Object.freeze(SectionType);